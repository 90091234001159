import React from 'react';
import PropTypes from 'prop-types';
import EditButton from '../components/EditButton';
import FacilityClientForm from './FacilityClientForm';
import FacilitySettingsForm from '../FacilitySettingsForm/FacilitySettingsForm';
import SettingsIcon from '@material-ui/icons/Settings';

export default function FacilityClientEditButton({ ...props }) {
  const { mode } = props;

  if (mode === 'add') {
    return <EditButton
      title="機構"
      form={FacilityClientForm}
      {...props}
    />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <EditButton
        title="機構"
        form={FacilityClientForm}
        tooltip='修改機構設定'
        {...props}
      />
      <EditButton
        title="機構"
        form={FacilitySettingsForm}
        icon={SettingsIcon}
        tooltip='修改機構設定'
        {...props}
      />
    </div>
  );
}


FacilityClientEditButton.propTypes = {
  mode: PropTypes.string,
};
