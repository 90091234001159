import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import FormDialog from 'components/FormDialog';
import AdminRecurringOrderForm from './AdminRecurringOrderForm';
import { formatDatetime } from 'utilities/format';
import { Semaphore } from 'async-mutex';
import { request } from 'utilities/graph';
import {
  getRecurringOrder,
} from 'graphql/queries';

import {
  createRecurringOrder,
  createRecurringOrderLog,
  adminCreateOrder,
} from 'graphql/mutations';
import cache from 'utilities/cache';
import moment from 'moment';

export default function AdminRecurringOrderEditButton({
  title,
  mode = 'add',
  item,
  onUpdate,
  open: inOpen = false,
  exitConfirm = false,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(inOpen);
  const [editItem, setEditItem] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState('group');
  const [toCreateOrders, setToCreateOrders] = useState([]);
  const [elders, setElders] = useState([]);
  const [toCreateRecurringOrder, setToCreateRecurringOrder] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createResults, setCreateResults] = useState([]);
  const [createErrors, setCreateErrors] = useState([]);
  const [orderCreated, setOrderCreated] = useState(false);

  const handleButtonClick = (event) => {
    if (mode === 'add') {
      setAnchorEl(event.currentTarget);
    } else {
      if (item.elderId) {
        setEditMode('single');
      } else {
        setEditMode('group');
      }
      setOpen(true);
      setEditItem(item);
    }
  };

  const handleMenuClick = (editMode) => () => {
    if (!open) {
      setEditMode(editMode);
      setIsLoading(false);
      setOrderCreated(false);
      setOpen(true);
      setEditItem(item);
    }
    setAnchorEl(null);
  };

  const getRecurringOrderUpdated = async (recurringOrderId) => {
    const { data: { getRecurringOrder: data } } = await request(getRecurringOrder, { id: recurringOrderId });
    return data;
  };

  const handleComplete = async () => {
    if (orderCreated) {
      setOpen(false);
      setOrderCreated(false);
      setOpenConfirmDialog(false);
      return;
    }
    setIsLoading(true);

    let results = [];
    const errors = [];
    const elderNameInErrors = [];
    if (toCreateOrders.length !== 0) {
      const s = new Semaphore(10);
      await Promise.all(toCreateOrders.map((order) =>
        s.runExclusive(async () => {
          try {
            const res = await request(adminCreateOrder, { input: { orders: [order] } });
            const { data: { adminCreateOrder: { data } } } = res;
            results = [...results, ...data];
            setCreateResults(results);
          } catch (e) {
            global.logger.debug(e);
            let message = '';
            if (e.errors && e.errors[0]) {
              switch (e.errors[0].message) {
                case 'The string supplied did not seem to be a phone number':
                  message = `熊貓外送: ${t('送餐對象')}電話不完整或有誤`;
                  break;
                case 'Unable to process order\norder is outside deliverable range':
                  message = `熊貓外送: ${t('送餐對象')}地址不在服務範圍內`;
                  break;
                default:
                  message = e.errors[0].message;
              }
            }
            errors.push({
              elderId: order.elderId,
              message,
            });
            const elderName = (elders.find(({ id })=> id === order.elderId) || {}).name;
            if (elderName) {
              elderNameInErrors.push(elderName);
            }
          }
        }),
      ));
    }

    const now = moment().toISOString();
    Object.assign(toCreateRecurringOrder, {
      lastExecutedAt: now,
      lastExecutedStatus: errors.length === 0 ? 'success' : 'failed',
      lastExecutedStatusMessage: elderNameInErrors.length === 0 ? '' : `新增訂單失敗: ${elderNameInErrors.join(' ')}`,
    });

    try {
      const { data: { createRecurringOrder: { id: recurringOrderId } } } = await request(createRecurringOrder, { input: toCreateRecurringOrder });
      const username = cache.get('app:username');
      const resultsGroupedByDeliveryBy = results.reduce((acc, order) => {
        const key = order.deliveryBy;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(order);
        return acc;
      }, {});
      const toCreateRecurringOrderLog = [];
      Object.keys(resultsGroupedByDeliveryBy).map((deliveryBy) => {
        toCreateRecurringOrderLog.push({
          orderIds: resultsGroupedByDeliveryBy[deliveryBy].map(({ id }) => id),
          recurringOrderId,
          deliveryBy: toCreateRecurringOrder.deliveryBy,
          date: deliveryBy.slice(0, 10),
          createdAt: now,
          createdBy: username,
          updatedAt: now,
          updatedBy: username,
        });
      });
      if (toCreateRecurringOrderLog.length !== 0) {
        await Promise.all(toCreateRecurringOrderLog.map((recurringOrderLog) => {
          request(createRecurringOrderLog, { input: recurringOrderLog });
        }));
      }
      const recurringOrder = await getRecurringOrderUpdated(recurringOrderId); // in order to get the data in the table joined
      onUpdate && onUpdate(recurringOrder);
    } catch (e) {
      console.log(e);
    }

    setCreateErrors(errors);
    setOrderCreated(true);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={'新增資料'}>
        <IconButton
          aria-label={mode}
          size={'small'}
          onClick={handleButtonClick}
        >
          {mode ==='edit' ? <EditIcon /> : <AddIcon />}
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={handleMenuClick('group')}>新增送餐群組週期性排單</MenuItem>
        <MenuItem onClick={handleMenuClick('single')}>新增單一送餐對象週期性排單</MenuItem>
      </Menu>
      {open &&
        <FormDialog
          title={title}
          openOnInit={true}
          onClose={() => {
            setOpen(false);
            setEditItem();
          }}
          exitConfirm={exitConfirm}
        >
          <AdminRecurringOrderForm
            formData={editItem}
            onComplete={(data) => {
              if (mode === 'add') {
                console.log(data);
                const { toCreateOrders, elders, toCreateRecurringOrder } = data;
                setToCreateOrders(toCreateOrders);
                setElders(elders);
                setToCreateRecurringOrder(toCreateRecurringOrder);
                setOpenConfirmDialog(true);
              } else {
                setOpen(false);
                setEditItem();
                onUpdate && onUpdate(data);
              }
            }}
            editMode={editMode}
            mode={mode}
          />
        </FormDialog>}
      {openConfirmDialog &&
        <FormDialog
          title={'訂單列表'}
          openOnInit={true}
          fullScreen={true}
          hideCloseButton={true}
        >
          <Grid container spacing={2}>
            <Grid item container spacing={1}>
              {toCreateOrders.length !== 0 &&
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <ListAltIcon style={{ marginRight: 4 }}/>
                  <Typography variant="h6">
                    請檢閱後按下確定新增下列訂單
                  </Typography>
                </Grid>}
              {toCreateOrders.length === 0 &&
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <Typography variant="h6">
                    此週期性排單在30天內沒有需要新增的訂單，請確認送餐對象與此排單的送餐週期設定是否正確，若仍要新增此週期性排單設定請按確定。
                  </Typography>
                </Grid>}
              {
                toCreateOrders.map((order, index) => (
                  <Grid item container xs={12} key={index} spacing={1} style={{ marginLeft: 20 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="p">
                        {(elders.find(({ id })=> id === order.elderId) || {}).name || ''}
                      </Typography>
                    </Grid>
                    {order.deliveryDatetimes.map((datetime) => {
                      const matched = createResults.find(({ elderId, deliveryBy }) => elderId === order.elderId && deliveryBy === datetime);
                      return (
                        <Grid item sm={4} md={3} lg={2} container align="center" key={datetime}>
                          <Grid item xs={12} container align="center">
                            {isLoading && !matched && <CircularProgress color="primary" size={24} />}
                            {(isLoading || orderCreated) && matched && <CheckIcon color="primary" />}
                            {(!isLoading && orderCreated) && !matched && <CancelIcon color="secondary" />}
                            <Typography style={{ marginTop: 2, marginLeft: 4 }}>
                              {formatDatetime(datetime, { weekday: true })}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Typography variant="h5" component="p" color="secondary">
                        {(createErrors.find(({ elderId })=> elderId === order.elderId) || {}).message || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              {!orderCreated &&
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenConfirmDialog(false);
                }}
                style={{ marginRight: 16 }}
                disabled={isLoading}
              >
                取消
              </Button>}
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={handleComplete}
              >
                {orderCreated ? '關閉' : '確定'}
                {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
              </Button>
            </Grid>
          </Grid>
        </FormDialog>
      }
    </React.Fragment>
  );
}

AdminRecurringOrderEditButton.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  item: PropTypes.object,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  exitConfirm: PropTypes.bool,
};
