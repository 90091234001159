import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import {
  getOrdersByClientByDate,
} from './queries';
import OrderCard from './OrderCard';
import { asyncListAll } from 'utilities/graph';
import cache from 'utilities/cache';

export default function MyOrders() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:clientId');
      const orders = await asyncListAll(getOrdersByClientByDate, { clientId });
      global.logger.debug(orders);
      setOrders(orders.reverse());
    })();
  }, []);

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={2}>
        {orders.map((order, index)=>(
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <OrderCard order={order} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

MyOrders.propTypes = {};
