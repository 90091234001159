import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import TagChip from 'components/TagChip';

const useStyles = makeStyles({
  mediaContainer: {
    position: 'relative',
  },
  media: {
    height: 150,
    opacity: 0.1,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  card: {
    cursor: 'pointer',
    height: '100%',
  },
});

export default function RestaurantMeals({ restaurant, onMealClick, filters, addedMeals = [] }) {
  const classes = useStyles();
  const [filteredMeals, setFilteredMeals] = useState([]);

  useEffect(() => {
    global.logger.debug('filters', filters);

    const filtered = restaurant.meals.filter((meal) => {
      let shouldDisplay = true;
      Object.keys(filters).forEach((key) => {
        if (key === 'text') {
          shouldDisplay = [
            meal.name, meal.description,
            meal.tagsString,
          ].some((value) => {
            return value ? value.toLowerCase().includes(filters[key]) : false;
          });
          return;
        }
        const targetValues = filters[key].filter((x) => x);
        if (targetValues.length === 0) {
          return;
        }
        global.logger.debug('key', key, targetValues);
        // compare key to subcategory
        const result = meal.tags.items.some((item) => {
          const tagItem = item[Object.keys(item)[0]];
          if (tagItem.subcategory === key) {
            return targetValues.includes(tagItem.label);
          } else {
            return false;
          }
        });
        if (!result) {
          shouldDisplay = false;
        }
      });

      return shouldDisplay;
    });

    setFilteredMeals(filtered);
  }, [filters]);

  if (filteredMeals.length === 0) {
    return <div/>;
  }

  return <Grid container spacing={2} style={{ padding: 16 }}>
    <Grid item xs={12}>
      {restaurant.name}
      <Divider />
    </Grid>
    {
      filteredMeals.map((meal) => {
        const { id, imageUrl, name, price, tags } = meal;
        const imgSrc = imageUrl || '/images/meal.webp';
        const quantity = (addedMeals.find((addedMeal) => addedMeal.meal.id === id) || {}).quantity;
        return (
          <Grid container item xs={6} sm={4} md={3} lg={2} xl={2} key={id}>
            <Badge badgeContent={quantity} color="secondary" />
            <Grid item xs={12}>
              <Card className={classes.card} onClick={() => onMealClick(meal, restaurant)}>
                <div className={classes.mediaContainer} >
                  <CardMedia
                    className={classes.media}
                    image={imgSrc}
                    title="照片"
                    component="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/meal.webp';
                    }}
                  />
                  <div className={classes.imageContainer}>
                    <img className={classes.img}
                      src={imgSrc}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/meal.webp';
                      }}
                    />
                  </div>
                </div>
                <CardContent style={{ position: 'relative', padding: 8 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="body1" color="textPrimary">
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="body2" color="textSecondary" >
                        ${price}
                      </Typography>
                    </Grid>
                  </Grid>

                  {tags.items.map(({ tag }, index)=>(
                    <TagChip
                      key={index}
                      tag={tag}
                    />
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      })}
  </Grid>;
}
RestaurantMeals.propTypes = {
  restaurant: PropTypes.object.isRequired,
  onMealClick: PropTypes.func.isRequired,
  filters: PropTypes.object,
  addedMeals: PropTypes.array,
};

