import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { Storage } from 'aws-amplify';
import {
  getDeliveryStaffOrdersByClientByDate,
} from './queries';
import QueryGenerator from 'components/QueryGenerator';
import DeliveryStaffOrderDiaryCard from 'views/Admin/DeliveryDiary/DeliveryStaffOrderDiaryCard';
import { request } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { useCache } from 'CacheProvider';

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').startOf('day').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).endOf('day').format('YYYY-MM-DD');

const INFINITE_DATA_SHOW_PER_REQUEST = 12;
const DEFAULT_IMAGE_URL = '/images/default_delivery_diary.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export default function DeliveryDiary({
  organizationId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [showedData, setShowedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { selectedUserGroupParams } = useCache();
  const { organizationData } = selectedUserGroupParams;

  const facilityOptions = useMemo(() => {
    if (organizationData && organizationData.clients && organizationData.clients.items) {
      return organizationData.clients.items.map(({ client }) => (
        { value: client.id, label: client.facilitySettings.dba || client.name }
      ));
    }
    return [];
  }, [organizationData]);

  const indexes = [
    {
      name: 'getDeliveryStaffOrdersByClientByDate',
      label: '按客戶查詢',
      partitionKey: 'clientId',
      sortKey: 'deliveryBy',
      fields: [
        {
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: facilityOptions,
        },
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
      ],
      operation: getDeliveryStaffOrdersByClientByDate,
    },
  ];

  useEffect(() => {
    setFilteredData(data);
    setShowedData([...data.filter((x, index) => index < INFINITE_DATA_SHOW_PER_REQUEST)]);
  }, [data]);

  const handleUpdate = async (items) => {
    let data = [];

    let visitorMode = false;
    if (organizationData?.clients?.items && items.length > 0) {
      const clientParams = organizationData?.clients?.items.find((data) => data.clientId === items[0].clientId);
      if (clientParams && clientParams.visitorMode) {
        visitorMode = true;
      }
    }

    const updatedItems = await Promise.all(items.map(async (deliveryStaffOrder) => {
      if (!deliveryStaffOrder.order) {
        const { data: { getOrder: order } } = await request( /* GraphQL */ `
              query GetOrder($id: ID!) {
                getOrder(id: $id) {
                  county
                  elder {
                    name
                    nickname
                  }
                }
              }
            `, { id: deliveryStaffOrder.orderId });
        deliveryStaffOrder.order = order;
      }

      deliveryStaffOrder.photoS3Keys = deliveryStaffOrder.photoS3Keys || [];

      if (deliveryStaffOrder.photoS3Keys[0] && deliveryStaffOrder.thumbnail !== DEFAULT_IMAGE_URL) {
        deliveryStaffOrder.thumbnail = await Storage.get(deliveryStaffOrder.photoS3Keys[0]);
      } else {
        deliveryStaffOrder.thumbnail = DEFAULT_IMAGE_URL;
      }
      deliveryStaffOrder.visitorMode = visitorMode;
      return deliveryStaffOrder;
    }));

    data = [...data, ...updatedItems];

    setData(data.sort(sortBy('deliveryBy', true)));
  };

  const queryDefaultParams = {
    status: 'completed',
    clientId: facilityOptions.length && facilityOptions[0].value,
    deliveryBy: {
      from: pastThreeDays,
      to: today,
    },
  };

  return (
    <InfiniteScroll
      scrollableTarget="app-container"
      dataLength={showedData.length}
      next={(...args) => {
        global.logger.debug('next', args);
        if (showedData.length === filteredData.length) return;
        setShowedData([...filteredData.filter((x, index) => index < showedData.length + INFINITE_DATA_SHOW_PER_REQUEST)]);
      }}
      height='calc(100vh - 116px)' // 64px appbar + 52px footer
      hasMore={showedData.length < filteredData.length}
      loader={
        <p style={{ textAlign: 'center' }}>
          <h4>載入中...</h4>
        </p>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
        </p>
      }
    >
      <Grid container spacing={1} className={classes.root} id="delivery-diary-container">
        <Grid item xs={12} >
          <QueryGenerator
            indexes={indexes}
            defaultIndex={0}
            defaultParams={queryDefaultParams}
            onUpdate={handleUpdate}
            appendNewData={true}
            limit={200}
          />
        </Grid>
        <Grid container spacing={2} style={{ padding: 8 }}>
          {showedData.map((item, index)=>(
            <Grid item xs={4} sm={4} md={4} lg={3} key={index}>
              <DeliveryStaffOrderDiaryCard
                deliveryStaffOrder={item}
                hideNames={item.visitorMode}
              />
              {(index + 1) % 6 === 0 && index + 1 !== showedData.length && <div className="page-break" />}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </InfiniteScroll>
  );
}

DeliveryDiary.propTypes = {
  deliveryStaffId: PropTypes.string,
  elderId: PropTypes.string,
  clientId: PropTypes.string,
  organizationId: PropTypes.string,
};
