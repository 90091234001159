import i18n from 'i18next';
import { reportTypes } from '@silvergatedelivery/constants';

const schema = {
  'title': '基本資料',
  'type': 'object',
  'required': [
    'isActive', 'type', 'usersLimit', 'eldersLimit',
    'name', 'phoneNumber', 'email', 'county', 'translation',
  ],
  'definitions': {
    'registerSteps': {
      'enumNames': [
        '輸入地址',
        '輸入相關經驗和自我介紹',
        '輸入緊急聯絡人',
        '輸入銀行帳號',
      ],
      'enum': ['Address', 'Introduction', 'EmergencyContact', 'BankAccount'],
    },
    'reportTypes': {
      'enumNames': reportTypes.map(({ label }) => label),
      'enum': reportTypes.map(({ value }) => value),
    },
  },
  'properties': {
    'isActive': {
      'type': 'number',
      'title': '狀態',
      'enum': [0, 1, 2],
      'enumNames': ['待審核', '使用中', '已停用'],
      'default': 1,
    },
    'type': {
      'type': 'string',
      'title': '類別',
      'enum': ['facility'],
      'enumNames': ['機構'],
      'default': 'facility',
    },
    'county': {
      'type': 'string',
      'title': '所在縣市',
    },
    'translation': {
      'type': ['string', 'null'],
      'title': '介面用詞',
      'enum': ['silvergate', 'medical'],
      'enumNames': ['長照送餐', '到府醫療'],
      'default': 'silvergate',
    },
    'usersLimit': {
      'type': 'number',
      'title': '可登入帳號上限',
      'default': 1,
    },
    'eldersLimit': {
      'type': 'number',
      'title': `可擁有${i18n.t('送餐對象')}上限`,
      'default': 1,
    },
    'name': {
      'type': 'string',
      'title': '姓名/名稱',
    },
    'username': {
      'type': ['string', 'null'],
      'title': '登入帳號',
    },
    'contact': {
      'type': ['string', 'null'],
      'title': '聯絡人姓名',
    },
    'email': {
      'type': 'string',
      'title': 'Email',
      'format': 'email',
    },
    'phoneNumber': {
      'type': 'string',
      'title': '電話號碼',
    },
    'phoneNumber2': {
      'type': ['string', 'null'],
      'title': '電話號碼2',
    },
    'phoneNumber3': {
      'type': ['string', 'null'],
      'title': '電話號碼3',
    },
    'basicFee': {
      'type': ['number', 'null'],
      'title': `基本里程${i18n.t('送餐費用')}`,
      'default': 50,
    },
    'basicDistanceInKM': {
      'type': ['number', 'null'],
      'title': '基本里程公里數',
      'default': 5,
    },
    'extraFeeInKM': {
      'type': ['number', 'null'],
      'title': `額外里程每公里${i18n.t('送餐費用')}`,
      'default': 5,
    },
    'featureConfiguration': {
      'required': [],
      'type': ['object', 'null'],
      'title': '',
      'properties': {
        'skipDocumentsUpload': {
          'type': 'boolean',
          'title': `${i18n.t('送餐大使')}註冊時不需要上傳相關文件`,
          'default': false,
        },
        'hideSToken': {
          'type': 'boolean',
          'title': `${i18n.t('送餐大使')}APP不顯示S Token資訊`,
          'default': false,
        },
        'useDedicatedDeliveryStaffs': {
          'type': 'boolean',
          'title': `限制該機構所屬的${i18n.t('送餐大使')}只能點選同機構的訂單`,
          'description': `該機構的訂單也將只能由所屬之${i18n.t('送餐大使')}所承接`,
          'default': false,
        },
        'dementiaQuestionnaire': {
          'type': 'boolean',
          'title': '參加失智防治推廣問卷調查',
          'default': false,
        },
        'skipRegisterSteps': {
          'type': 'array',
          'uniqueItems': true,
          'title': `${i18n.t('送餐大使')}註冊時省略過下列註冊步驟`,
          'items': { '$ref': '#/definitions/registerSteps' },
          'default': [],
        },
        'supportNumber': {
          'type': ['string', 'null'],
          'title': `客服電話號碼`,
          'default': '',
        },
        'reportTypes': {
          'type': 'array',
          'uniqueItems': true,
          'title': '報表種類',
          'items': { '$ref': '#/definitions/reportTypes' },
          'default': [],
        },
      },
    },
    'address': {
      'title': '地址',
      'type': 'object',
    },
  },
};

export default schema;
