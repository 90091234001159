import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from 'components/Tab/VerticalTabs';
// import DeliveryStaffOrderTable from 'components/DeliveryStaffOrderTable';
import OrderTable from 'components/OrderTable';
import RecurringOrderTable from 'components/RecurringOrderTable';
import DeliveryStaffDocumentTable from 'components/DeliveryStaffDocumentTable';
// import NotificationTable from 'components/NotificationTable';
// import DeliveryStaffTransactionTable from 'components/DeliveryStaffTransactionTable';
// import DeliveryStaffStatementTable from 'components/DeliveryStaffStatementTable';
import { Colors } from '@silvergatedelivery/constants';
import DeliveryDiary from 'views/Admin/DeliveryDiary/DeliveryDiary';
import Schedule from 'views/Admin/Schedule/Schedule';
import DeliveryStaffCard from 'components/Card/DeliveryStaffCard';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    // minHeight: 'calc(100vh - 64px)',
    // height: '100%',
    backgroundColor: Colors.background.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  space: {
    height: theme.spacing(2),
  },
  // centerContainer: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export default function DeliveryStaffDetails({ id: inId, computedMatch, isNested }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    if (!id) return;

    const tabs = [
      // {
      //   label: '基本資料',
      //   component: <DeliveryStaffCard title="基本資料" id={id} hideExpand={true} showMap={true} />,
      // },
      {
        label: '資料檔案',
        component: <DeliveryStaffDocumentTable nested={true} deliveryStaffId={id} padding={0}/>,
      },
      // {
      //   label: t('送餐紀錄'),
      //   component: <DeliveryStaffOrderTable nested={true} deliveryStaffId={id} padding={0} />,
      // },
      {
        label: '行事曆',
        component: <Schedule
          deliveryStaffId={id}
          showElderSelector={false}
          showLocationSelector={false}
          showDeliverStaffSelector={false}
          defaultLocations={[]}
          defaultOrderStatus={[
            'ready',
            'readyForPickup',
            'delivering',
            'delivered',
            'completed',
            'cancelled',
          ]} />,
      },
      {
        label: '訂單紀錄',
        component: <OrderTable nested={true} deliveryStaffId={id} padding={0} />,
      },
      {
        label: '週期性排單',
        component: <RecurringOrderTable nested={true} deliveryStaffId={id} padding={0} />,
      },
      {
        label: t('送餐關懷'),
        component: <DeliveryDiary deliveryStaffId={id} />,
      },
      // {
      //   label: '通知紀錄',
      //   component: <NotificationTable nested={true} deliveryStaffId={id} padding={0} />,
      // },
      // {
      //   label: '交易紀錄',
      //   component: <DeliveryStaffTransactionTable nested={true} deliveryStaffId={id} padding={0} />,
      // },
      // {
      //   label: '結算紀錄',
      //   component: <DeliveryStaffStatementTable nested={true} deliveryStaffId={id} padding={0} />,
      // },
    ];
    setTabs(tabs);
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={2} lg={2} xl={3}>
          <DeliveryStaffCard title="基本資料" id={id} hideExpand={true} showMap={true} />
        </Grid>
        <Grid item xs={12} md={10} lg={10} xl={9}>
          <VerticalTabs
            tabs={tabs}
            padding={0}
            isNested={isNested}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

DeliveryStaffDetails.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  isNested: PropTypes.bool,
};
