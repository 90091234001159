import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { formatAddress } from 'utilities/format';
import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getRestaurantOwner, getRestaurant } from 'graphql/queries';
import RestaurantOwnerEditButton from 'forms/RestaurantOwnerForm/RestaurantOwnerEditButton';

export default function RestaurantOwnerCard({ id, restaurantId, title = `${i18n.t('餐廳')}負責人資料`, data: inData, showMap, ...props }) {
  const [data, setData] = useState({});

  const mappings = [
    { key: 'name', label: '名字' },
    { key: 'username', label: '帳號' },
    { key: 'phoneNumber', label: '電話' },
    { key: 'email', label: 'Email' },
    { key: 'address', label: '地址', format: formatAddress, showMap },
    { key: 'howDoYouKnowUs', label: '如何得知銀色大門' },
    { key: 'note', label: '註記' },
  ];

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (restaurantId) {
        const { data: { getRestaurant: { restaurantOwnerId } } } = await request(getRestaurant, { id: restaurantId });

        if (!restaurantOwnerId) return;

        const { data: { getRestaurantOwner: data } } = await request(getRestaurantOwner, { id: restaurantOwnerId });
        setData(data);
      } else
      if (id) {
        const { data: { getRestaurantOwner: data } } = await request(getRestaurantOwner, { id });
        setData(data);
      }
    })();
  }, [inData, id, restaurantId]);

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={`/restaurantOwner/${data.id}`}
      editButton={RestaurantOwnerEditButton}
      onUpdate={setData}
      {...props}
    />
  );
}

RestaurantOwnerCard.propTypes = {
  id: PropTypes.string,
  restaurantId: PropTypes.string,
  title: PropTypes.string,
  showMap: PropTypes.bool,
  data: PropTypes.object,
};
