import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDatetime } from 'utilities/format';
import InfoCard from '../Card/InfoCard';

export default function PublicOrderCard({ data: inData, onUpdate, ...props }) {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  let mappings = [];
  if (Object.keys(data).length !== 0) {
    mappings = [
      { key: 'id', label: '訂單編號' },
      { key: 'deliveryBy', label: '日期時間', format: formatDatetime },
      { key: 'restaurant', label: `${t('餐廳')}`, format: (restaurant) => restaurant.name },
      { key: 'mealItems', label: `${t('餐點')}`,
        format: (mealItems) => mealItems.map(({ name, quantity }) => `${name} x${quantity}`).join(', ') },
      { key: 'noteForMeal', label: `${t('餐點')}備註` },
      { key: 'noteForDelivery', label: `${t('送餐')}備註` },
      { key: 'total', label: `${t('餐點')}價格` },
    ];
    if (data.deliveryStaffFee !== 0) {
      mappings.push({ key: 'deliveryStaffFee', label: t('送餐費用') });
    }
  }

  useEffect(() => {
    if (inData) {
      setData(inData);
    }
  }, [inData]);

  return (
    <InfoCard
      title="訂單資料"
      mappings={mappings}
      data={data}
      {...props}
    />
  );
}

PublicOrderCard.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
