import { formatGender, formatAddress } from 'utilities/format';
import { utils, write } from 'sheetjs-style';
import JSZip from 'jszip';
import { getMonthDates } from './helpers';
import { unifyCellStyle } from './helpers';
import { download } from 'utilities/file';

const COUNTY = '雲林縣';

const monthMapping = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
const weekdays = ['週日', '週一', '週二', '週三', '週四', '週五', '週六'];

export default function writeYunlin({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  pandagoOrders,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [year, month] = selectedMonth.split('-');
  const yearMonthTitle = `${parseInt(year)-1911}年${month.replace(/^0+/, '')}月份`;

  // 移除沒有弱勢身份的訂單
  // const ordersForDisvantaged = orders.filter((order) => {
  //   const { disadvantagedTypesSlot } = elders.find((elder) => elder.id === order.elderId) || {};
  //   if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
  //     const slot = disadvantagedTypesSlot.find(({ start, end }) => order.date >= start && order.date < end);
  //     if (slot && slot.type === '一般') {
  //       return false;
  //     }
  //   }
  //   return true;
  // });

  const zip = new JSZip();

  const params = {
    elders,
    // orders: ordersForDisvantaged,
    orders,
    pandagoOrders,
    deliveryStaffs,
    yearMonthTitle,
    clientName,
    selectedMonth,
  };
  const workbookElder = utils.book_new();
  utils.book_append_sheet(workbookElder, getWorksheetElders(params), `${monthMapping[parseInt(month) - 1]}月份`);
  const workbookDeliveryStaff = utils.book_new();
  utils.book_append_sheet(workbookDeliveryStaff, getWorksheetDeliveryStaff(params), `${monthMapping[parseInt(month) - 1]}月份`);

  if (process.env.JEST_WORKER_ID) {
    return [
      write(workbookElder, { type: 'buffer', bookType: 'xlsx' }),
      write(workbookDeliveryStaff, { type: 'buffer', bookType: 'xlsx' }),
    ];
  }

  const wboutElder = write(workbookElder, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  zip.file(`志工送餐名冊-${clientName}.xlsx`, wboutElder, { binary: true });

  const wboutDeliveryStaff = write(workbookDeliveryStaff, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  zip.file(`志工次數月統計表-${clientName}.xlsx`, wboutDeliveryStaff, { binary: true });

  const zipContent = zip.generate({ type: 'blob' });
  download(`${clientName}__${COUNTY}核銷報表__${selectedMonth}.zip`, zipContent);
}

function getWorksheetElders({ elders = [], orders = [], deliveryStaffs, yearMonthTitle, clientName, selectedMonth }) {
  const elderRows = [];
  const monthDates = getMonthDates(selectedMonth, 'MM/DD');
  let index = 1;
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];
  let rowIndex = 3;
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      gender,
      address,
    }) => {
      const elderOrders = orders
        .filter(({ elderId }) => elderId === id);
      if (elderOrders.length === 0) {
        return;
      }
      const lunchOrders = elderOrders
        .filter(({ mealSlot }) => mealSlot === 'lunch');
      const dinnerOrders = elderOrders
        .filter(({ mealSlot }) => mealSlot === 'dinner');
      const formatElderRow = (orders) => {
        const elderRow = {
          序號: index,
          姓名: name,
          性別: formatGender(gender),
          身分證字號: identificationCardId,
          地址: formatAddress(address, { includeZipCode: false }),
        };
        const deliveryStaffIds = [...new Set(orders.map(({ deliveryStaffId }) => deliveryStaffId))];
        const deliveryStaffNames = deliveryStaffIds.map((deliveryStaffId) => {
          return (deliveryStaffs.find(({ id }) => id === deliveryStaffId) || {}).name || '';
        });
        monthDates.forEach(({ date, label }) => {
          const elderOrdersByDate = orders.filter((order) => order.date === date);
          elderRow[label] = elderOrdersByDate.length;
        });
        elderRow['志工'] = deliveryStaffNames.join('、\n');
        elderRow['合計'] = lunchOrders.length + dinnerOrders.length;
        elderRows.push(elderRow);
      };
      if (lunchOrders.length !== 0) {
        formatElderRow(lunchOrders);
        rowIndex += 1;
      }
      if (dinnerOrders.length !== 0) {
        formatElderRow(dinnerOrders);
        rowIndex += 1;
      }
      if (lunchOrders.length !== 0 && dinnerOrders.length !== 0) {
        worksheet['!merges'].push({ s: { r: rowIndex - 2, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
        worksheet['!merges'].push({ s: { r: rowIndex - 2, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
        worksheet['!merges'].push({ s: { r: rowIndex - 2, c: 2 }, e: { r: rowIndex - 1, c: 2 } });
        worksheet['!merges'].push({ s: { r: rowIndex - 2, c: 3 }, e: { r: rowIndex - 1, c: 3 } });
        worksheet['!merges'].push({ s: { r: rowIndex - 2, c: 4 }, e: { r: rowIndex - 1, c: 4 } });
        worksheet['!merges'].push({
          s: { r: rowIndex - 2, c: Object.keys(elderRows[0]).length - 1 },
          e: { r: rowIndex - 1, c: Object.keys(elderRows[0]).length - 1 },
        });
        delete elderRows[elderRows.length - 1]['序號'],
        delete elderRows[elderRows.length - 1]['姓名'],
        delete elderRows[elderRows.length - 1]['性別'];
        delete elderRows[elderRows.length - 1]['身分證字號'];
        delete elderRows[elderRows.length - 1]['地址'];
        delete elderRows[elderRows.length - 1]['合計'];
      }
      index += 1;
    });

  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: monthDates.length + 6 } });
  utils.sheet_add_aoa(worksheet, [[`${clientName}    服務紀錄志工送餐名冊`]], { origin: 'A1' });
  worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: monthDates.length + 6 } });
  utils.sheet_add_aoa(worksheet, [[yearMonthTitle]], { origin: 'A2' });
  const dateLabels = monthDates.map(({ label }) => label);
  const weekLabels = monthDates.map(({ date }) => {
    const dateObj = new Date(date);
    return weekdays[dateObj.getDay()];
  });
  worksheet['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 3, c: 0 } });
  worksheet['!merges'].push({ s: { r: 2, c: 1 }, e: { r: 3, c: 1 } });
  worksheet['!merges'].push({ s: { r: 2, c: 2 }, e: { r: 3, c: 2 } });
  worksheet['!merges'].push({ s: { r: 2, c: 3 }, e: { r: 3, c: 3 } });
  worksheet['!merges'].push({ s: { r: 2, c: 4 }, e: { r: 3, c: 4 } });
  worksheet['!merges'].push({ s: { r: 2, c: monthDates.length + 5 }, e: { r: 3, c: monthDates.length + 5 } });
  worksheet['!merges'].push({ s: { r: 2, c: monthDates.length + 6 }, e: { r: 3, c: monthDates.length + 6 } });
  utils.sheet_add_aoa(worksheet, [['序號', '姓名', '性別', '身分證字號', '地址', ...dateLabels, '志工', '合計']], { origin: 'A3' });
  utils.sheet_add_aoa(worksheet, [[...weekLabels]], { origin: 'F4' });
  utils.sheet_add_json(worksheet, elderRows, { origin: 'A5', skipHeader: true });

  unifyCellStyle(worksheet, elderRows.length + 3);
  worksheet['A1'].s = {
    ...worksheet['A1'].s,
    font: { bold: true, sz: 26 },
    alignment: { horizontal: 'center' },
  };
  worksheet['A2'].s = { ...worksheet['A2'].s, alignment: { horizontal: 'right' } };
  worksheet['!cols'] = [
    { wch: 5 }, { wch: 13 },
    { wch: 5 }, { wch: 14 },
    { wch: 43 },
    ...monthDates.map(() => ({ wch: 5 })),
    { wch: 13 }, { wch: 5 },
  ];
  worksheet['!rows'] = [{ hpx: 34 }];
  return worksheet;
}

function getWorksheetDeliveryStaff({ elders, orders, deliveryStaffs, yearMonthTitle, clientName, selectedMonth }) {
  const monthDates = getMonthDates(selectedMonth, 'MM/DD');
  const data = [];
  let index = 1;
  const totalByDate = {};
  deliveryStaffs.forEach(({ id, name }) => {
    const deliveryStaffOrders = orders.filter(({ deliveryStaffId }) => deliveryStaffId === id);
    if (deliveryStaffOrders.length === 0) {
      return;
    }
    let total = 0;
    const row = {
      '序號': index,
      '志工': name,
    };
    monthDates.forEach(({ date, label }) => {
      if (!totalByDate[date]) {
        totalByDate[date] = 0;
      }
      const elderOrdersByDate = deliveryStaffOrders.filter(({ date: orderDate }) => orderDate === date);
      let value = 0;
      if (elderOrdersByDate.some(({ mealSlot }) => mealSlot === 'lunch')) {
        value += 1;
        total += 1;
        totalByDate[date] += 1;
      }
      if (elderOrdersByDate.some(({ mealSlot }) => mealSlot === 'dinner')) {
        value += 1;
        total += 1;
        totalByDate[date] += 1;
      }
      row[label] = value;
    });
    row['合計'] = total;
    if (!totalByDate['合計']) {
      totalByDate['合計'] = 0;
    }
    totalByDate['合計'] += total;
    data.push(row);
    index += 1;
  });
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: monthDates.length + 1 } },
    { s: { r: 1, c: 1 }, e: { r: 1, c: monthDates.length + 1 } },
    { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
    { s: { r: 2, c: monthDates.length + 2 }, e: { r: 3, c: monthDates.length + 2 } },
  ];
  utils.sheet_add_aoa(worksheet, [[clientName]], { origin: 'B1' });
  utils.sheet_add_aoa(worksheet, [[`${yearMonthTitle}志工服務天數統計表`]], { origin: 'B2' });
  const dateLabels = monthDates.map(({ label }) => label);
  const weekLabels = monthDates.map(({ date }) => {
    const dateObj = new Date(date);
    return weekdays[dateObj.getDay()];
  });
  utils.sheet_add_aoa(worksheet, [['序號', '日期', ...dateLabels, '合計']], { origin: 'A3' });
  utils.sheet_add_aoa(worksheet, [['志工', ...weekLabels]], { origin: 'B4' });
  utils.sheet_add_json(worksheet, data, { origin: 'A5', skipHeader: true });
  const offset = data.length + 5;
  worksheet['!merges'].push({ s: { r: offset - 1, c: 0 }, e: { r: offset - 1, c: 1 } });
  utils.sheet_add_aoa(worksheet, [['志工合計']], { origin: `A${offset}` });
  utils.sheet_add_json(worksheet, [totalByDate], { origin: `C${offset}`, skipHeader: true });

  unifyCellStyle(worksheet, offset);
  worksheet['B1'].s = {
    ...worksheet['B1'].s,
    font: { bold: true, sz: 36 },
    alignment: { horizontal: 'center' },
  };
  worksheet['B2'].s = {
    ...worksheet['B2'].s,
    font: { bold: true, sz: 24 },
    alignment: { horizontal: 'center' },
  };

  worksheet['!cols'] = [
    { wch: 5 },
    { wch: 13 },
    ...dateLabels.map(() => ({ wch: 5 })),
    { wch: 5 },
  ];
  worksheet['!rows'] = [{ hpx: 50 }, { hpx: 33 }];

  return worksheet;
}
