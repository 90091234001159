import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { updateFacilitySettings } from 'graphql/mutations';
import ImageGalleryUploader from 'components/ImageGalleryUploader';
import { useCache } from 'CacheProvider';
import uiSchema from './uiSchema';

export default function FacilitySettingsForm({ formData: inFormData, ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const [currentImageS3Keys, setCurrentImageS3Keys] = useState([]);
  const [newImageS3Keys, setNewImageS3Keys] = useState([]);
  const { appGroup, setLogoS3Keys } = useCache();
  const facilityMode = appGroup === 'FacilityAdmins';

  useEffect(() => {
    if (inFormData && inFormData.facilitySettings && inFormData.facilitySettings.logoS3Keys && inFormData.facilitySettings.logoS3Keys[0]) {
      setCurrentImageS3Keys(inFormData.facilitySettings.logoS3Keys);
      setNewImageS3Keys(inFormData.facilitySettings.logoS3Keys);
    }
  }, [inFormData]);

  const updateFunc = async (data) => {
    data.facilitySettings.logoS3Keys = newImageS3Keys;
    if (facilityMode) {
      setLogoS3Keys([...newImageS3Keys]);
    }
    await request(updateFacilitySettings, { input: data.facilitySettings });
  };

  if (!inFormData) {
    return <></>;
  }

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={() => {}}
      updateFunc={updateFunc}
      formData={inFormData}
      dirty={true}
      {...props}
    >
      <Typography variant="h5">
        商標圖示
      </Typography>
      <Typography variant="body2" color='textSecondary'>
        顯示在左側功能清單上方
      </Typography>
      <Divider />
      <ImageGalleryUploader
        s3Prefix={`facilitySettings/${inFormData.id}/logo`}
        s3Keys={currentImageS3Keys}
        onUpdateS3Keys={setNewImageS3Keys}
      />
    </DataForm>
  );
}

FacilitySettingsForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
