import React from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { resetFormCache } from 'forms/formCache';
import { request } from 'utilities/graph';
import { createDeliveryStaff, updateDeliveryStaff } from 'graphql/mutations';
import schema from './schema';
import uiSchema from './uiSchema';
import { deliveryStaffStatus } from '@silvergatedelivery/constants';

import {
  getClientIdSchema,
} from 'forms/schemas';
import { useCache } from 'CacheProvider';

Object.assign(schema.properties.isActive, {
  type: 'number',
  enum: deliveryStaffStatus.map(({ value }) => value),
  enumNames: deliveryStaffStatus.map(({ label }) => label),
  default: 1,
});

export default function DeliverStaffForm({ ...props }) {
  const { appGroup } = useCache();

  const createFunc = async (data) => {
    data.county = data.county || data.address.county;

    await request(createDeliveryStaff, { input: data });
    resetFormCache('allDeliveryStaffs');
  };

  const updateFunc = async (data) => {
    data.county = data.county || data.address.county;

    await request(updateDeliveryStaff, { input: data });
    resetFormCache('allDeliveryStaffs');
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      extMappings={[{
        key: 'clientId',
        func: (clientId) => {
          const adminMode = appGroup === 'Admins';
          return getClientIdSchema(clientId, '所屬機構', false, adminMode);
        },
      }]}
      {...props}
    />
  );
}

DeliverStaffForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
