import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// // import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import { asyncListAll, request } from 'utilities/graph';
import {
  getElder,
  getDeliveryStaff,
  listDeliveryStaffs,
  getDeliveryStaffsByCountyByIsActive,
  listElders,
  getEldersByCountyByCreatedAt,
  getOrdersByElderByDate,
  getOrdersByDeliveryStaffByDate,
  getOrdersByCountyByDate,
  getOrdersByStatusByDate,
} from 'graphql/queries';
import { counties, TIME_ZONE } from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';
import ScheduleCalendar from 'components/Schedule/ScheduleCalendar';
import OrderStatusChip from 'components/OrderStatusChip';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
import cache from 'utilities/cache';
import { getTranslatedOrderStatus, getTranslatedMealSlots } from 'utilities/translate';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const DATE_FORMAT = 'YYYY-MM-DD';

const getDates = (from, to) => {
  const dates = [moment(to).tz(TIME_ZONE).format(DATE_FORMAT)];

  while (dates[0] > from) {
    const prev = moment(dates[0]).tz(TIME_ZONE).add(-1, 'day').format(DATE_FORMAT);
    dates.unshift(prev);
  }
  return dates;
};

export default function Schedule({
  deliveryStaffId,
  elderId,
  defaultLocations = [cache.get('app:location')].filter((x) => x),
  defaultOrderStatus = [
    'waitingForDeliveryStaff',
    'reMatchingDeliveryStaff',
    'ready',
    'readyForPickup',
    'delivering',
  ],
  showLocationSelector = true,
  showDeliverStaffSelector = true,
  showElderSelector = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [targetOrderStatus, setTargetOrderStatus] = useState(defaultOrderStatus);
  const [targetCounties, setTargetCounties] = useState(defaultLocations);
  const [targetDeliveryStaffIds, setTargetDeliveryStaffIds] = useState([]);
  const [targetElderIds, setTargetElderIds] = useState([]);
  const [targetMealSlots, setTargetMealSlots] = useState(['lunch', 'dinner']);

  // const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState([]);
  const [elderOptions, setElderOptions] = useState([]);
  const [dateInfo, setDateInfo] = useState();
  const [orderMappings, setOrderMappings] = useState({});
  // const [restaurantSchedules, setRestaurantSchedules] = useState([]);

  const onDateChange = (newDateInfo) => {
    const newFromDate = moment(newDateInfo.startStr).tz(TIME_ZONE).format(DATE_FORMAT);
    const newToDate = moment(newDateInfo.endStr).tz(TIME_ZONE).format(DATE_FORMAT);

    const { fromDate, toDate } = dateInfo || {};

    if (newFromDate !== fromDate || newToDate !== toDate) {
      setDateInfo({
        fromDate: newFromDate,
        toDate: newToDate,
        dates: getDates(newFromDate, newToDate),
      });
    }
  };

  useEffect(() => {
    if (!dateInfo) return;

    (async () => {
      if (elderId && targetElderIds.length === 0) return;
      if (deliveryStaffId && targetDeliveryStaffIds.length === 0) return;

      setIsLoading(true);

      let orders = [];
      const { dates } = dateInfo;

      global.logger.debug('Fetching orders', dateInfo);

      const queryPromsies = [];

      const asyncListAllWrapper = async (queryName, params) => {
        const key = JSON.stringify(params);

        if (orderMappings[key]) {
          // global.logger.debug(`${key} exists, use cache data`);
          return orderMappings[key];
        }

        const data = await asyncListAll(queryName, params);
        orderMappings[key] = data;

        setOrderMappings({ ...orderMappings });

        return data;
      };

      if (elderId && targetElderIds[0]) {
        targetElderIds.forEach((elderId) => {
          dates.forEach((date) => {
            queryPromsies.push(asyncListAllWrapper(getOrdersByElderByDate, {
              elderId,
              date: {
                eq: date,
              },
            }));
          });
        });
      } else
      if (deliveryStaffId && targetDeliveryStaffIds[0]) {
        targetDeliveryStaffIds.forEach((deliveryStaffId) => {
          dates.forEach((date) => {
            queryPromsies.push(asyncListAllWrapper(getOrdersByDeliveryStaffByDate, {
              deliveryStaffId,
              date: {
                eq: date,
              },
            }));
          });
        });
      } else
      if (targetCounties.length > 0) {
        targetCounties.forEach((county) => {
          dates.forEach((date) => {
            queryPromsies.push(asyncListAllWrapper(getOrdersByCountyByDate, {
              county,
              date: {
                eq: date,
              },
            }));
          });
        });
      } else
      if (targetOrderStatus.length > 0) {
        targetOrderStatus.forEach((status) => {
          dates.forEach((date) => {
            queryPromsies.push(asyncListAllWrapper(getOrdersByStatusByDate, {
              status,
              date: {
                eq: date,
              },
            }));
          });
        });
      }

      const results = await Promise.all(queryPromsies);
      results.forEach((items) => {
        orders = [...orders, ...items];
      });

      setOrders(orders);

      setIsLoading(false);
    })();
  }, [
    lastUpdatedAt,
    targetCounties,
    targetOrderStatus,
    targetDeliveryStaffIds,
    targetElderIds,
    elderId,
    deliveryStaffId,
    dateInfo,
  ]);

  useEffect(() => {
    const filteredOrders = orders
      .filter((item) => {
        let shouldRender = true;
        if (targetCounties.length > 0 && !targetCounties.includes(item.county)) {
          shouldRender = false;
        }
        if (targetOrderStatus.length > 0 && !targetOrderStatus.includes(item.status)) {
          shouldRender = false;
        }
        if (targetMealSlots.length > 0 && !targetMealSlots.includes(item.mealSlot)) {
          shouldRender = false;
        }
        if (targetDeliveryStaffIds.length > 0 && !targetDeliveryStaffIds.includes(item.deliveryStaffId)) {
          shouldRender = false;
        }
        if (targetElderIds.length > 0 && !targetElderIds.includes(item.elderId)) {
          shouldRender = false;
        }
        // if (targetDeliveryStaffIds.length > 0 && !targetDeliveryStaffIds.includes(item.deliveryStaffId)) {
        //   shouldRender = false;
        // }
        return shouldRender;
      });

    global.logger.debug('orders', { orders, filteredOrders });

    setFilteredOrders(filteredOrders);
  }, [orders, targetCounties, targetOrderStatus, targetMealSlots, targetElderIds, targetDeliveryStaffIds]);

  useEffect(() => {
    if (!showElderSelector) return;
    (async () => {
      let elders = [];
      if (elderId) {
        const { data: { getElder: data } } = await request(getElder, { id: elderId });
        elders = [data];
      } else
      if (targetCounties.length === 0) {
        elders = await asyncListAll(listElders);
      } else {
        await Promise.all(targetCounties.map(async (county) => {
          const data = await asyncListAll(getEldersByCountyByCreatedAt, {
            county,
          });
          elders = [...elders, ...data];
        }));
      }

      global.logger.debug('elders', elders);

      setElderOptions([
        ...elders.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          };
        }).sort(sortBy('label'))]);

      if (elderId) {
        setTargetElderIds([elderId]);
      }
    })();
  }, [targetCounties, elderId, showElderSelector]);

  useEffect(() => {
    if (!showDeliverStaffSelector) {
      if (deliveryStaffId) {
        setTargetDeliveryStaffIds([deliveryStaffId]);
      }
      return;
    }

    (async () => {
      let deliveryStaffs = [];
      if (deliveryStaffId) {
        const { data: { getDeliveryStaff: data } } = await request(getDeliveryStaff, { id: deliveryStaffId });
        deliveryStaffs = [data];
      } else
      if (targetCounties.length === 0) {
        deliveryStaffs = await asyncListAll(listDeliveryStaffs);
      } else {
        await Promise.all(targetCounties.map(async (county) => {
          const data = await asyncListAll(getDeliveryStaffsByCountyByIsActive, {
            county,
            isActive: { eq: 1 },
          });
          deliveryStaffs = [...deliveryStaffs, ...data];
        }));
      }

      setDeliveryStaffOptions([
        { label: '尚無指派', value: null },
        ...deliveryStaffs.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          };
        }).sort(sortBy('label'))]);

      if (deliveryStaffId) {
        setTargetDeliveryStaffIds([deliveryStaffId]);
      }
    })();
  }, [targetCounties, deliveryStaffId, showDeliverStaffSelector]);

  const renderChipValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value, index) => {
        return (<Chip
          key={index}
          label={value}
          // className={classes.chip}
          // style={{ color, backgroundColor }}
        />);
      })}
    </div>
  );

  const renderDeliveryStaffChipValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value, index) => {
        return (<Chip
          key={index}
          label={deliveryStaffOptions.find((item)=>item.value === value).label}
        />);
      })}
    </div>
  );

  const renderElderChipValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value, index) => {
        return (<Chip
          key={index}
          label={elderOptions.find((item)=>item.value === value).label}
        />);
      })}
    </div>
  );

  const renderStatusChipValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value) => {
        return (<OrderStatusChip status={value} key={value} />);
      })}
    </div>
  );
  const translatedMealSlots = getTranslatedMealSlots();
  const renderMealSlotValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((value, index) => {
        return (<Chip
          key={index}
          label={translatedMealSlots.find((item)=>item.value === value).label}
        />);
      })}
    </div>
  );

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid container item md={2} className={classes.content} direction="column">
          {showLocationSelector &&
            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-counties-label">縣市</InputLabel>
              <Select
                labelId="mutiple-counties-label"
                id="mutiple-counties"
                multiple
                value={targetCounties}
                onChange={(event) => setTargetCounties(event.target.value)}
                input={<Input id="select-multiple-counties" />}
                renderValue={renderChipValue}
                disabled={isLoading}
              >
                {counties.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={targetCounties.indexOf(item) > -1} />
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-orderStatus-label">狀態</InputLabel>
            <Select
              labelId="mutiple-orderStatus-label"
              id="mutiple-orderStatus"
              multiple
              value={targetOrderStatus}
              onChange={(event) => setTargetOrderStatus(event.target.value)}
              input={<Input id="select-multiple-orderStatus" />}
              renderValue={renderStatusChipValue}
              disabled={isLoading}
            >
              {getTranslatedOrderStatus().map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={targetOrderStatus.indexOf(item.value) > -1} />
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-mealSlot-label">{`${t('午餐')}/${t('晚餐')}`}</InputLabel>
            <Select
              labelId="mutiple-mealSlot-label"
              id="mutiple-mealSlot"
              multiple
              value={targetMealSlots}
              onChange={(event) => setTargetMealSlots(event.target.value)}
              input={<Input id="select-multiple-mealSlot" />}
              renderValue={renderMealSlotValue}
              disabled={isLoading}
            >
              {translatedMealSlots.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={targetMealSlots.indexOf(item.value) > -1} />
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showDeliverStaffSelector &&
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-deliveryStaffIds-label">{t('送餐大使')}</InputLabel>
            <Select
              labelId="mutiple-deliveryStaffIds-label"
              id="mutiple-deliveryStaffIds"
              multiple
              value={targetDeliveryStaffIds}
              onChange={(event) => setTargetDeliveryStaffIds(event.target.value)}
              input={<Input id="select-multiple-deliveryStaffIds" />}
              renderValue={renderDeliveryStaffChipValue}
              disabled={isLoading}
            >
              {deliveryStaffOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={targetDeliveryStaffIds.indexOf(item.value) > -1} />
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {showElderSelector &&
            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-elderIds-label">{t('送餐對象')}</InputLabel>
              <Select
                labelId="mutiple-elderIds-label"
                id="mutiple-elderIds"
                multiple
                value={targetElderIds}
                onChange={(event) => setTargetElderIds(event.target.value)}
                input={<Input id="select-multiple-elderIds" />}
                renderValue={renderElderChipValue}
                disabled={isLoading}
              >
                {elderOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={targetElderIds.indexOf(item.value) > -1} />
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          <Button
            onClick={() => {
              setOrderMappings({});
              setLastUpdatedAt(Date.now());
            }}
            disabled={isLoading}
          >
            {!isLoading &&
            <React.Fragment>
              <RefreshIcon fontSize="small" />
              重新整理
            </React.Fragment>}
            {isLoading && <CircularProgress color="primary" size={16} />}
          </Button>
          <AdminOrderEditButton
            mode="create"
            type="button"
            onUpdate={() => {
              setOrderMappings({});
              setLastUpdatedAt(Date.now());
            }}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={10} className={classes.content}>
          <ScheduleCalendar
            orders={filteredOrders}
            // restaurantSchedules={restaurantSchedules}
            onDateChange={onDateChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}


Schedule.propTypes = {
  elderId: PropTypes.string,
  deliveryStaffId: PropTypes.string,
  defaultLocations: PropTypes.array,
  defaultOrderStatus: PropTypes.array,
  showLocationSelector: PropTypes.bool,
  showElderSelector: PropTypes.bool,
  showDeliverStaffSelector: PropTypes.bool,
};
