import moment from 'moment-timezone';

import {
  getIssuesByClientByCreatedAt,
  getOrdersByClientByCreatedAt,
  getAuditTrailsByFacilityIdByTimestamp,
} from 'graphql/queries';
import {
  getOrdersByClientByDate,
} from './queries';
import { asyncListAll, asyncListAllAndUpdateLastItem } from 'utilities/graph';
import { TIME_ZONE } from '@silvergatedelivery/constants';

export function getStartEndByPeriodName(inPeriod) {
  const [period, startBaseline, endBaseline, exactStartDate, exactEndDate] = inPeriod.split('/');
  if (exactStartDate && exactEndDate) {
    const start = moment(exactStartDate).tz(TIME_ZONE).startOf('day');
    const end = moment(exactEndDate).tz(TIME_ZONE).startOf('day');
    return {
      start: start.toISOString(),
      end: end.toISOString(),
      startFormat: start.format('YYYY-MM-DD'),
      endFormat: end.format('YYYY-MM-DD'),
    };
  }
  const startBaseNumber = parseInt(startBaseline || '0');
  const endBaseNumber = parseInt(endBaseline || '0');
  const start = moment().add(startBaseNumber, `${period}s`).tz(TIME_ZONE).startOf(period);
  const end = moment().add(endBaseNumber, `${period}s`).tz(TIME_ZONE).endOf(period);
  return {
    start: start.toISOString(),
    end: end.toISOString(),
    prevStart: moment().tz(TIME_ZONE).add(-1 + startBaseNumber, `${period}s`).startOf(period).toISOString(),
    prevEnd: moment().tz(TIME_ZONE).add(-1 + endBaseNumber, `${period}s`).endOf(period).toISOString(),
    startFormat: start.format('YYYY-MM-DD'),
    endFormat: end.format('YYYY-MM-DD'),
  };
}

export function getDatesBetween(startISO, endISO, format = 'YYYY-MM-DD') {
  const startDate = moment(startISO);
  const endDate = moment(endISO);
  const dates = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    dates.push(startDate.format(format));
    startDate.add(1, 'days');
  }

  return dates;
}

export const getOrders = async (clientId, start, end, onUpdateLastItem) => {
  return asyncListAllAndUpdateLastItem(getOrdersByClientByDate, {
    clientId,
    date: {
      between: [start, end],
    },
    limit: 1000,
  }, onUpdateLastItem);
};

export const getNewOrders = async (clientId, start, end) => {
  return asyncListAll(getOrdersByClientByCreatedAt, {
    clientId,
    createdAt: {
      between: [start, end],
    },
  });
};

export const getIssues = async (clientId, start, end) => {
  return asyncListAll(getIssuesByClientByCreatedAt, {
    clientId,
    createdAt: {
      between: [start, end],
    },
  });
};

export const getLoginLogs = async (clientId, start, end) => {
  return asyncListAll(getAuditTrailsByFacilityIdByTimestamp, {
    facilityId: clientId,
    timestamp: {
      between: [start, end],
    },
  });
};
