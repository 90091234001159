import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function InvoiceInput({ data, onChange }) {
  const [invoice, setInvoice] = useState(data);
  const updateInvoice = (toUpdateProps = {}) => {
    const updatedOrder = Object.assign(invoice, toUpdateProps);
    setInvoice({ ...updatedOrder });
    onChange && onChange(updatedOrder);
  };

  return (
    <Grid container spacing={0} style={{ paddingTop: 16 }}>
      <Grid item xs={12} container justifyContent="flex-start">
        <FormControl>
          <FormLabel id="invoice-type-radio-buttons-group-label">發票類型</FormLabel>
          <RadioGroup
            row
            aria-labelledby="invoice-type--radio-buttons-group-label"
            name="invoice-type--radio-buttons-group"
            value={invoice.type}
            onChange={(e) => updateInvoice({ type: e.target.value })}
          >
            <FormControlLabel value="individual" control={<Radio />} label="個人" />
            <FormControlLabel value="enterprise" control={<Radio />} label="企業" />
            <FormControlLabel value="donation" control={<Radio />} label="捐贈給社團法人銀色大門老人福利組織" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} container spacing={2} justifyContent="center">
        {/* 個人 */}
        {invoice.type === 'individual' && (
          <Grid item xs={12}>
            <TextField
              label='載具'
              type="text"
              value={invoice.carrier}
              onChange={(e) => updateInvoice({ carrier: e.target.value })}
              fullWidth
            />
          </Grid>
        )}
        {/* 企業 */}
        {invoice.type === 'enterprise' && (
          <>
            <Grid item xs={12}>
              <TextField
                label={'抬頭*'}
                type="text"
                value={invoice.title}
                onChange={(e) => updateInvoice({ title: e.target.value })}
                fullWidth
                error={invoice.title.length === 0}
                helperText={ invoice.title.length === 0 ? '必填' : '' }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={'統編*'}
                type="text"
                value={invoice.taxIdNumber}
                onChange={(e) => updateInvoice({ taxIdNumber: e.target.value })}
                fullWidth
                error={invoice.taxIdNumber.length === 0}
                helperText={ invoice.taxIdNumber.length === 0 ? '必填' : '' }
              />
            </Grid>
          </>
        )}

        {(invoice.type === 'individual' || invoice.type === 'enterprise') && (
          <Grid item xs={12}>
            <TextField
              label={'信箱*'}
              type="text"
              value={invoice.email}
              onChange={(e) => updateInvoice({ email: e.target.value })}
              fullWidth
              error={invoice.email.length === 0}
              helperText={ invoice.email.length === 0 ? '必填' : '' }
            />
          </Grid>
        )}

        {/* 捐贈 */}
        {invoice.type === 'donation' && (
          <Grid item xs={12} container justifyContent="flex-start">
            <TextField
              type="text"
              value={'非常感謝您的捐贈'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

InvoiceInput.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};
