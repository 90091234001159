import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { resetFormCache } from 'forms/formCache';
import { request } from 'utilities/graph';
import { getClientsByShortId } from 'graphql/queries';
import { createClient, updateClient } from 'graphql/mutations';
import uiSchema from './uiSchema';

export default function IndividualClientForm({ ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);
  const createFunc = async (data) => {
    data.county = data.county || data.address.county;
    let shortId;
    while (!shortId) {
      const tempShortId = (Math.floor(Math.random() * 900000) + 100000).toString();
      const { data: { getClientsByShortId: { items } } } = await request(getClientsByShortId, { shortId: tempShortId });
      if (items.length === 0) {
        shortId = tempShortId;
      }
    }
    data.shortId = shortId;
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;
    await request(createClient, { input: data });
  };

  const updateFunc = async (data) => {
    data.county = data.county || data.address.county;
    if (!data.featureConfiguration) {
      data.featureConfiguration = {
        skipRegisterSteps: [],
        skipDocumentsUpload: false,
        hideSToken: false,
        supportNumber: '',
        useDedicatedDeliveryStaffs: false,
        dementiaQuestionnaire: false,
        reportTypes: [],
      };
    }
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;

    const newData = JSON.parse(JSON.stringify(data));
    delete newData.facilitySettings;

    await request(updateClient, { input: newData });
    resetFormCache('allClients');
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

IndividualClientForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
