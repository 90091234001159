import i18n from 'i18next';
import { request, asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import cache from 'utilities/cache';
import {
  counties,
  countiesDistricts,
} from '@silvergatedelivery/constants';
import { formCache } from './formCache';

const filterByLocation = (item) => {
  const target = cache.get('app:location');
  if (!target) return true;
  if (item.county) {
    return item.county === target;
  } else
  if (item.address) {
    return item.address.county === target;
  } else {
    return true;
  }
};

export const getElderIdSchema = async (inElderId, inTitle, inClientId, shouldReturnOriginalRecords = false) => {
  if (inElderId) {
    const { data: { getElder: elder } } = await request( /* GraphQL */ `
      query GetElder($id: ID!) {
        getElder(id: $id) {
          id
          county
          name
          deliveryGroupId
          sortOrder
          noteForDelivery
          noteForMeal
          note
          phoneNumber
          email
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
        }
      }
    `, { id: inElderId });
    return {
      enum: [elder.id],
      enumNames: [elder.name],
      default: inElderId,
    };
  }

  let records = [];

  const clientId = cache.get('app:facilityId') || inClientId;
  const target = cache.get('app:location');

  if (clientId) {
    records = await asyncListAll( /* GraphQL */ `
      query GetEldersByClientByStatus(
        $clientId: ID
        $status: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelElderFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getEldersByClientByStatus(
          clientId: $clientId
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
        items {
          id
          county
          name
          status
          deliveryGroupId
          sortOrder
          noteForDelivery
          noteForMeal
          note
          phoneNumber
          email
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
        }
        nextToken
      }
    }
    `, { clientId, status: { eq: '使用中' } });
  } else
  if (target) {
    records = await asyncListAll( /* GraphQL */ `
      query GetEldersByCountyByCreatedAt(
        $county: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelElderFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getEldersByCountyByCreatedAt(
          county: $county
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            county
            name
            deliveryGroupId
            sortOrder
            noteForDelivery
            noteForMeal
            note
            phoneNumber
            email
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
          }
          nextToken
        }
      }
    `, { county: target, filter: { status: { eq: '使用中' } } });
  } else {
    records = await asyncListAll( /* GraphQL */ `
    query ListElders(
      $filter: ModelElderFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listElders(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          county
          name
          deliveryGroupId
          sortOrder
          noteForDelivery
          noteForMeal
          note
          phoneNumber
          email
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          emergencyContact {
            name
            relationship
            phoneNumber
            phoneNumber2
          }
        }
        nextToken
      }
    }
  `, { filter: { status: { eq: '使用中' } } });
  }

  records = records.sort(sortBy('name'));
  // .filter(filterByLocation);

  if (shouldReturnOriginalRecords) {
    return records;
  }

  return {
    enum: records.map(({ id }) => id),
    enumNames: records.map(({ name }) => name),
  };
};

export const getDeliveryGroupIdSchema = async (inDeliveryGroupId, inTitle, inClientId, shouldReturnOriginalRecords = false) => {
  if (inDeliveryGroupId) {
    const { data: { getDeliveryGroup: deliveryGroup } } = await request( /* GraphQL */ `
      query GetDeliveryGroup($id: ID!) {
        getDeliveryGroup(id: $id) {
          id
          clientId
          isActive
          name
          description
          note
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    `, { id: inDeliveryGroupId });
    return {
      enum: [inDeliveryGroupId],
      enumNames: [deliveryGroup ? deliveryGroup.name : '已刪除'],
      default: inDeliveryGroupId,
    };
  }

  let records = [];

  const clientId = cache.get('app:facilityId') || inClientId;
  // const target = cache.get('app:location');

  if (clientId) {
    records = await asyncListAll( /* GraphQL */ `
      query GetDeliveryGroupsByClientByIsActive(
        $clientId: ID
        $isActive: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeliveryGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getDeliveryGroupsByClientByIsActive(
          clientId: $clientId
          isActive: $isActive
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            clientId
            isActive
            name
            description
            note
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          nextToken
        }
      }
    `, { clientId, isActive: { eq: 1 } });
  }

  records = records.sort(sortBy('name'));

  if (shouldReturnOriginalRecords) {
    return records;
  }

  return {
    enum: records.map(({ id }) => id),
    enumNames: records.map(({ name }) => name),
  };
};

export const getClientIdSchema = async (inClientId, inTitle, shouldReturnOriginalRecords = false, includeAll = false, nonNull = true) => {
  const clientId = cache.get('app:facilityId') || inClientId;

  if (!includeAll && clientId) {
    const { data: { getClient: client } } = await request( /* GraphQL */ `
      query GetClient($id: ID!) {
        getClient(id: $id) {
          id
          name
          county
        }
      }
    `, { id: clientId });
    return {
      enum: [clientId],
      enumNames: [(client?.name || clientId)],
      default: clientId,
    };
  }

  let records;
  let cacheKey = '';

  if (inTitle === '所屬機構' || inTitle === '機構') {
    cacheKey = 'allFacilities';

    records = formCache[cacheKey] || (await asyncListAll( /* GraphQL */ `
      query GetClientsByTypeByCounty(
        $type: ClientType
        $county: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelClientFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getClientsByTypeByCounty(
          type: $type
          county: $county
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            county
          }
          nextToken
        }
      }
    `, { type: 'facility', filter: { isActive: { eq: 1 } } }));
  } else {
    cacheKey = 'allClients';

    records = formCache[cacheKey] || (await asyncListAll( /* GraphQL */ `
      query ListClients(
        $filter: ModelClientFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            county
          }
          nextToken
        }
      }
    `, { filter: { isActive: { eq: 1 } } }));
  }

  formCache[cacheKey] = records;

  records = records
    .sort(sortBy('name'))
    .filter(filterByLocation);

  if (shouldReturnOriginalRecords) {
    return records;
  }
  if (records.length !== 0) {
    if (nonNull) {
      return {
        enum: records.map(({ id }) => id),
        enumNames: records.map(({ name }) => name),
      };
    }
    return {
      enum: [null, ...records.map(({ id }) => id)],
      enumNames: ['無', ...records.map(({ name }) => name)],
    };
  }
  return undefined;
};

export const getOrganizationIdSchema = async (inOrganizationId) => {
  const cacheKey = 'allOrganizations';
  let records = formCache[cacheKey] || (await asyncListAll( /* GraphQL */ `
      query ListOrganizations(
        $filter: ModelOrganizationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
          }
          nextToken
        }
      }
    `));

  formCache[cacheKey] = records;

  records = records
    .sort(sortBy('name'))
    .filter(filterByLocation);

  if (records.length !== 0) {
    return {
      enum: ['N/A', ...records.map(({ id }) => id)],
      enumNames: ['無', ...records.map(({ name }) => name)],
    };
  }

  return undefined;
};

// used for DataForm
export const getRestaurantIdSchemaByClientIds = (clientIds) =>
  async (
    inRestaurantId, inTitle,
  ) =>
    await getRestaurantIdSchema(inRestaurantId, inTitle, clientIds, false);

export const getRestaurantIdSchema = async (inRestaurantId, inTitle, inClientId, shouldReturnOriginalRecords = false) => {
  const clientIds = inClientId ? (Array.isArray(inClientId) ? inClientId : [inClientId]) : [];
  let records = [];

  if (clientIds.length) {
    records = (await Promise.all(clientIds.map(async (clientId) => await asyncListAll( /* GraphQL */ `
      query GetRestaurantsByClientByIsActive(
        $clientId: ID
        $isActive: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelRestaurantFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getRestaurantsByClientByIsActive(
          clientId: $clientId
          isActive: $isActive
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            owner
            isActive
            county
            name
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            openingHours {
              sunday {
                startTime
                endTime
              }
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
            }
          }
          nextToken
        }
      }
    `, { clientId, isActive: { eq: 1 } })))).flat();
    records = records.map((item) => {
      item.label = `${item.name}`;
      return item;
    });
  } else {
    const cacheKey = 'allRestaurants';
    records = formCache[cacheKey] || await asyncListAll( /* GraphQL */ `
      query ListRestaurants(
        $filter: ModelRestaurantFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listRestaurants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            owner
            isActive
            county
            name
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            openingHours {
              sunday {
                startTime
                endTime
              }
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
            }
          }
          nextToken
        }
      }
    `, { filter: { isActive: { eq: 1 } } });
    formCache[cacheKey] = records;

    records = records.map((item) => {
      item.label = `${item.address.zipCode} ${item.address.county} - ${item.name}`;
      return item;
    });
  }

  records = records
    .sort(sortBy('label'));
  // .filter(filterByLocation);

  if (shouldReturnOriginalRecords) {
    return records;
  }

  return {
    enum: records.map(({ id }) => id),
    enumNames: records.map(({ label }) => label),
  };
};

export const getRestaurantOwnerIdSchema = async () => {
  let records = await asyncListAll( /* GraphQL */ `
    query ListRestaurantOwners(
      $filter: ModelRestaurantOwnerFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRestaurantOwners(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          isActive
          county
          username
          name
        }
        nextToken
      }
    }
    `);

  records = records.map((item) => {
    item.label = item.name;
    return item;
  });

  records = records
    .sort(sortBy('label'));
  // .filter(filterByLocation);

  return {
    enum: [null, ...records.map(({ id }) => id)],
    enumNames: ['無', ...records.map(({ label }) => label)],
  };
};

// used for DataForm
export const getDeliveryStaffIdSchemaByClientIds = (clientIds) =>
  async (
    inDeliveryStaffId, inTitle,
  ) =>
    await getDeliveryStaffIdSchema(inDeliveryStaffId, inTitle, clientIds, true, false, false);

export const getDeliveryStaffIdSchema = async (inDeliveryStaffId, inTitle,
  inClientId, includePartners = false, includeMultiStopPartners = false, nonNull = false,
) => {
  const clientIds = inClientId ? (Array.isArray(inClientId) ? inClientId : [inClientId]) : [];
  const target = cache.get('app:location');

  let records = [];

  if (clientIds.length) {
    records = (await Promise.all(clientIds.map(async (clientId) => await asyncListAll( /* GraphQL */ `
      query GetDeliveryStaffsByClientIdByIsActive(
        $clientId: ID
        $isActive: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeliveryStaffFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getDeliveryStaffsByClientIdByIsActive(
          clientId: $clientId
          isActive: $isActive
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            username
            isActive
            county
            name
            tier
          }
          nextToken
        }
      }
    `, { clientId, isActive: { eq: 1 } })))).flat();
  } else
  if (target) {
    records = await asyncListAll( /* GraphQL */ `
      query GetDeliveryStaffsByCountyByCreatedAt(
        $county: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeliveryStaffFilterInput
        $limit: Int
        $nextToken: String
      ) {
        getDeliveryStaffsByCountyByCreatedAt(
          county: $county
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            username
            isActive
            county
            name
            tier
          }
          nextToken
        }
      }
    `, { county: target, filter: { isActive: { eq: 1 } } });
  } else {
    const cacheKey = 'allDeliveryStaffs';
    records = formCache[cacheKey] || await asyncListAll( /* GraphQL */ `
      query ListDeliveryStaffs(
        $filter: ModelDeliveryStaffFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listDeliveryStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            username
            isActive
            county
            name
            tier
          }
          nextToken
        }
      }
    `, { filter: { isActive: { eq: 1 } } });
    formCache[cacheKey] = records;
  }

  if (inDeliveryStaffId && !records.some(({ id }) => id === inDeliveryStaffId)) {
    const { data: { getDeliveryStaff: deliveryStaff } } = await request( /* GraphQL */ `
      query GetDeliveryStaff($id: ID!) {
        getDeliveryStaff(id: $id) {
          id
          username
          isActive
          county
          name
          tier
        }
      }
    `, { id: inDeliveryStaffId });
    records.unshift(deliveryStaff);
  }

  // 加入 pandago/uber/ilink
  const partners = [];
  if (includePartners) {
    partners.push(...[
      'uber',
      'pandago',
      'ilink',
      'lalamove',
    ]);
  }
  if (includeMultiStopPartners) {
    partners.push('lalamove(multi-stop)');
  }
  if (includePartners || includeMultiStopPartners) {
    await Promise.all(partners.map(async (partnerUsername) => {
      if (!records.find(({ username }) => username === partnerUsername)) {
        const [partnerDeliveryStaff] = await asyncListAll( /* GraphQL */ `
          query GetDeliveryStaffsByUsername(
            $username: String
            $sortDirection: ModelSortDirection
            $filter: ModelDeliveryStaffFilterInput
            $limit: Int
            $nextToken: String
          ) {
            getDeliveryStaffsByUsername(
              username: $username
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                id
                username
                isActive
                county
                name
                tier
              }
              nextToken
            }
          }
        `, { username: partnerUsername });

        if (partnerDeliveryStaff) {
          records.push(partnerDeliveryStaff);
        }
      }
    }));
  }

  records = records
    .sort(sortBy('name'))
    .sort(sortBy('tier'));
  // .filter(filterByLocation);

  if (nonNull) {
    return {
      enum: records.map(({ id }) => id),
      enumNames: records.map(({ tier, name }) => `[${tier}] ${name}`),
    };
  }
  return {
    enum: [null, ...records.map(({ id }) => id)],
    enumNames: ['無', ...records.map(({ tier, name }) => `[${tier}] ${name}`)],
  };
};

// https://valerii-udodov.com/posts/react-json-schema-form/
// Bug: nested depedencies not updated when changing the parent value
// https://github.com/rjsf-team/react-jsonschema-form/issues/3450
export const getAddressSchema = (targetZipcodes) => async () => {
  let targetCounties = counties;
  let targetCountiesDistricts = countiesDistricts;

  if (targetZipcodes && targetZipcodes.length !== 0) {
    targetCounties = [];
    targetCountiesDistricts = [];
    countiesDistricts.forEach((countiesDistrict) => {
      const { zipCode, county } = countiesDistrict;
      if (targetZipcodes && targetZipcodes.length > 0 && !targetZipcodes.includes(zipCode)) {
        return;
      }
      if (!targetCounties.includes(county)) {
        targetCounties.push(county);
      }
      targetCountiesDistricts.push(countiesDistrict);
    });
  }

  const addressSchema = {
    'required': [
      'zipCode',
      'county',
      'district',
      'street',
    ],
    'properties': {
      'county': {
        'type': 'string',
        'title': '縣市',
        'enum': targetCounties,
        'default': targetCounties[0],
      },
      'street': {
        'type': 'string',
        'title': '道路或街名或村里名稱',
      },
      'note': {
        'type': ['string', 'null'],
        'title': '地址備註',
      },
      'lat': {
        'type': ['number', 'null'],
        'title': '經度',
        'description': '保留0的話，系統會自動定位經緯度',
        'default': 0,
      },
      'lng': {
        'type': ['number', 'null'],
        'title': '緯度',
        'description': '保留0的話，系統會自動定位經緯度',
        'default': 0,
      },
    },
    'dependencies': {
      'county': {
        'oneOf': targetCounties.map((county) => {
          let availableDistricts = [];
          targetCountiesDistricts
            .filter((item) => item.county === county)
            .forEach(({ districts }) => {
              if (districts) {
                availableDistricts = [...availableDistricts, ...districts];
              }
            });

          return {
            'properties': {
              'county': {
                'enum': [county],
              },
              'district': {
                'type': 'string',
                'title': '鄉鎮市區',
                'enum': availableDistricts,
              },
            },
            'required': [
              'county',
              'district',
              'street',
            ],
          };
        }),
      },
    },
  };

  return addressSchema;
};

export const getCountySchema = async () => {
  return {
    'type': 'string',
    'title': '縣市',
    'enum': [
      ...counties,
      'N/A',
    ],
  };
};

export const getEmergencyContactSchema = async () => {
  return {
    'required': [
      'name',
      'relationship',
      'phoneNumber',
    ],
    'properties': {
      'name': {
        'type': 'string',
        'title': '姓名',
      },
      'relationship': {
        'type': 'string',
        'title': '關係',
      },
      'phoneNumber': {
        'type': 'string',
        'title': '電話號碼',
      },
      'phoneNumber2': {
        'type': ['string', 'null'],
        'title': '電話號碼2',
      },
    },
  };
};

export const getMealItemsSchema = (mealOptions) => () => {
  return {
    'type': 'array',
    'title': i18n.t('餐點'),
    'items': {
      'required': [
        'name',
        'quantity',
      ],
      'type': 'object',
      'properties': {
        'name': {
          'type': 'string',
          'title': '名稱',
          'examples': mealOptions.map(({ name }) => name),
        },
        'quantity': {
          'type': 'number',
          'title': '數量',
          'default': 1,
        },
        'cost': {
          'type': 'number',
          'title': '進價',
          'default': 0,
        },
        'price': {
          'type': 'number',
          'title': '價格',
          'default': 0,
        },
        'note': {
          'type': ['string', 'null'],
          'title': '註記',
        },
      },
    },
    'default': [],
    'minItems': 1,
  };
};

export const getSponsorProgramIdSchema = async (inId) => {
  if (inId) {
    const { data: { getSponsorshipProgram: data } } = await request( /* GraphQL */ `
      query GetSponsorshipProgram($id: ID!) {
        getSponsorshipProgram(id: $id) {
          id
          isActive
          name
          count
          unit
          unitValue
        }
      }
    `, { id: inId });

    const records = [data];

    return {
      enum: records.map(({ id }) => id),
      enumNames: records.map(({ name, count, unit, unitValue }) => `${name} (${count} ${unit} x $${unitValue})`),
      default: inId,
    };
  }

  const records = (await asyncListAll( /* GraphQL */ `
    query ListSponsorshipPrograms(
      $filter: ModelSponsorshipProgramFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSponsorshipPrograms(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          isActive
          name
          count
          unit
          unitValue
        }
        nextToken
      }
    }
  `)).sort(sortBy('name'));

  return {
    enum: records.map(({ id }) => id),
    enumNames: records.map(({ name, count, unit, unitValue }) => `${name} (${count} ${unit} x $${unitValue})`),
  };
};
