import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 60,
    borderRadius: 10,
    margin: 8,
  },
  biLogo: {
    border: '5px solid #034E8C',
    width: 150,
    height: 150,
    padding: 10,
    backgroundColor: '#fff',
  },
  logo: {
    border: '5px solid #00913A',
    width: 150,
    height: 150,
    padding: 20,
    backgroundColor: '#fff',
  },
}));

export default function CategorySelect({ onComplete }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* <img src={'/images/bi_app_banner_03.jpg'} style={{ height: 'auto', width: '100%' }}/> */}
        <img src={'/images/bi_app_banner.png'} style={{ height: 'auto', width: '100%' }}/>
      </Grid>
      <Grid container item alignItems="center" justifyContent="center" style={{ marginTop: -100 }}>
        {/* <Avatar alt="Logo" src="/images/bi_app_logo.png" className={classes.biLogo} /> */}
        <Avatar alt="Logo" src="/logos/logo_256.png" className={classes.logo} />
      </Grid>
      <Grid container item xs={12} justifyContent="center" style={{ marginTop: 16 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            onComplete('meals');
          }}
          size="large"
          className={classes.button}
        >
          {t('立即送餐')}
        </Button>
        {/* <Button
          disabled
          variant="contained"
          color="primary"
          onClick={()=>{
            onComplete('stuffs');
          }}
          size="large"
          className={classes.button}
        >
          安排宅配
        </Button> */}
      </Grid>
    </Grid>

  );
}

CategorySelect.propTypes = {
  onComplete: PropTypes.func,
};
