/* eslint-disable quotes, comma-dangle */
import i18n from "i18next";

const schema = {
  "type": "object",
  "required": [
    "status", "category", "deliveryStaffId", "restaurantId", "mealItems", "deliveryBy", "repeatOn", "startOn",
  ],
  "properties": {
    "clientId": {
      "type": ["string", "null"],
      "title": "機構"
    },
    "status": {
      "type": "string",
      "title": "狀態",
      "enum": ["使用中", "已停用"],
      "default": "使用中",
    },
    "category": {
      "type": ["string", "null"],
      "title": "訂單類別",
      "enum": ['送餐', '送物資', '送餐和物資', '藝術', '醫療'],
      "default": "送餐"
    },
    "deliveryGroupId": {
      "type": "string",
      "title": "送餐群組",
    },
    "elderId": {
      "type": "string",
      "title": "送餐對象",
    },
    "deliveryStaffId": {
      "type": "string",
      "title": i18n.t("送餐大使")
    },
    "restaurantId": {
      "type": "string",
      "title": i18n.t("餐廳")
    },
    "mealItems": {
      "type": "array"
    },
    "repeatOn": {
      "type": "array",
      "title": "送餐週期",
      "minItems": 0,
      "items": {
        "type": "integer",
        "enum": [1, 2, 3, 4, 5, 6, 0],
        "enumNames": ["星期ㄧ", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
      },
      "uniqueItems": true
    },
    "deliveryBy": {
      "type": "string",
      "title": "送餐時間",
      "format": "time"
    },
    "startOn": {
      "type": "string",
      "title": "開始日期",
      "format": "date"
    },
    "endOn": {
      "type": ["string", "null"],
      "title": "結束日期",
      "format": "date",
      "description": "可不輸入",
    },
  }
};

export default schema;
