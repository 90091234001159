import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import {
  getAuditTrailsByGroupNameByTimestamp,
  getAuditTrailsByFacilityIdByTimestamp,
  listAuditTrails,
} from 'graphql/queries';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').startOf('day').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).endOf('day').format('YYYY-MM-DD');

export default function AuditTrailTable({
  title = i18n.t('使用紀錄'),
  description,
  organizationId: inOrganizationId,
  clientId: inClientId,
  facilityId: inFacilityId,
  ...props
}) {
  const { selectedUserGroupParams } = useCache();

  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [listQueries, setListQueries] = useState();

  const organizationId = inOrganizationId || cache.get('app:organizationId');
  const facilityId = inFacilityId || cache.get('app:facilityId');
  // const clientId = inClientId || cache.get('app:clientId');

  const options = {};

  const columns = [
    // {
    //   name: 'id',
    //   label: '編號',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: 'timestamp',
      label: '時間',
      type: 'datetime',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'groupName',
      label: '權限',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '帳號',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'action',
      label: '操作動作',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },

  ];

  useEffect(() => {
    if (organizationId) {
      const { organizationClientIds } = selectedUserGroupParams;

      setListQueries(organizationClientIds.map((clientId) => {
        return {
          name: getAuditTrailsByFacilityIdByTimestamp,
          params: {
            facilityId: clientId,
          },
        };
      }));

      setIndexes(undefined);

      return;
    }

    if (facilityId) {
      setIndexes([
        {
          name: 'byFacilityIdByTimestamp',
          label: '按機構查詢',
          partitionKey: 'facilityId',
          sortKey: 'timestamp',
          fields: [
            // {
            //   label: '機構編號',
            //   key: 'facilityId',
            // },
            {
              label: `時間`,
              key: 'timestamp',
              type: 'datetime',
            },
          ],
          operation: getAuditTrailsByFacilityIdByTimestamp,
        },
      ]);

      setQueryParams({
        facilityId,
        timestamp: {
          from: pastThreeDays,
          to: today,
        },
      });

      return;
    }

    setIndexes([
      {
        name: 'getAuditTrailsByGroupNameByTimestamp',
        label: '按權限查詢',
        partitionKey: 'groupName',
        sortKey: 'timestamp',
        fields: [
          {
            label: '權限',
            key: 'groupName',
            type: 'select',
            options: [
              'admin',
              'organization',
              'facility',
              'delivery',
              'client',
            ].map((item) => {
              return { value: item, label: item };
            }),

          },
          {
            label: `時間`,
            key: 'timestamp',
            type: 'datetime',
          },
        ],
        operation: getAuditTrailsByGroupNameByTimestamp,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [
          {
            label: '操作動作',
            key: 'action',
          },
        ],
        operation: listAuditTrails,
      },
    ]);

    setQueryParams({
      groupName: 'admin',
      timestamp: {
        from: pastThreeDays,
        to: today,
      },
    });
  }, [organizationId, facilityId]);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        listQueries={listQueries}
        {...props}
      />
    </React.Fragment>
  );
}

AuditTrailTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
  clientId: PropTypes.string,
  facilityId: PropTypes.string,
};
