import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataForm from 'forms/DataForm';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Colors } from '@silvergatedelivery/constants';
import { getEldersByClientByStatus } from './queries';
import { asyncListAll } from 'utilities/graph';

import schema from './schema';
import uiSchema from './uiSchema';
import cache from 'utilities/cache';

export default function ElderInfo({ formData: inFormData, ...props }) {
  const { t } = useTranslation();
  const [elders, setElders] = useState([]);
  const [elderId, setElderId] = useState();
  const [formData, setFormData] = useState();

  const createFunc = async (data) => {
    global.logger.debug('createFunc data', data);
  };

  useEffect(() => {
    (async () => {
      const clientId = cache.get('app:clientId');
      if (!clientId) {
        setFormData(inFormData);
        return;
      }

      const elders = await asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: { eq: '使用中' },
      });

      if (elders.length > 0) {
        const {
          id,
          name,
          phoneNumber,
          address,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
        } = elders[0];
        setElders(elders);
        setElderId(id);
        setFormData({
          id,
          name,
          phoneNumber,
          address,
          noteForDelivery,
          noteForMeal,
          noteForHealth,
        });
      } else {
        setFormData(inFormData);
      }
    })();
  }, [inFormData]);

  if (!formData) return null;

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      {elderId &&
      <Grid item xs={12} style={{ zIndex: 100 }}>
        <FormControl fullWidth>
          <InputLabel id="date-label">
            送餐長輩
          </InputLabel>
          <Select
            fullWidth
            value={elderId}
            onChange={(e) => {
              setFormData();

              const { value: id } = e.target;
              setElderId(id);
              const { address, name, noteForDelivery, noteForMeal, noteForHealth, phoneNumber } = elders.find((x) => x.id === id);
              setFormData({
                id, name, address, noteForDelivery, noteForMeal, noteForHealth, phoneNumber,
              });
            }}
          >
            {elders.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>}
      <Grid item xs={12} style={{ marginTop: -300, zIndex: 1 }}>
        <DataForm
          schema={schema}
          uiSchema={uiSchema}
          createFunc={createFunc}
          updateFunc={createFunc}
          onComplete={props.onComplete}
          dirty={true}
          {...props}
          formData={formData}
        >
          <div style={{ backgroundColor: Colors.background.light, padding: 16, color: '#4d4d4d', borderRadius: 10, marginTop: 16, marginBottom: 16 }}>
            {t('運送時間因實際狀況、天氣、車隊派送情形為主。')}<br/>
            {t('運送距離上限：8公里內（以平台計算為主）')}
            {/* 運送費用規則：0km以上，＄60起，消費滿599免運 */}
          </div>
        </DataForm>
      </Grid>
    </Grid>
  );
}

ElderInfo.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
