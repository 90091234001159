import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { getRestaurantMealsByRestaurantByIsActive } from 'forms/AdminBulkOrderForm/queries';
import { getRestaurantIdSchema } from 'forms/schemas';
import { asyncListAll } from 'utilities/graph';
import { getConfigurationByCategoryByStatus } from 'graphql/queries';
import cache from 'utilities/cache';
import { useCache } from 'CacheProvider';

export default function RestaurantMealSelect({ onUpdate }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [restaurantIdSelected, setRestaurantIdSelected] = useState();
  const [mealItemName, setMealItemName] = useState('');
  const [mealItemQuantity, setMealItemQuantity] = useState(1);
  const [mealItemPrice, setMealItemPrice] = useState(0);
  const [mealItemCost, setMealItemCost] = useState(0);
  const [restaurantMealOptions, setRestaurantMealOptions] = useState({});
  const [orderConfigurations, setOrderConfigurations] = useState([]);
  const { isAdmin, facilityIds, appGroup } = useCache();
  const facilityMode = appGroup === 'FacilityAdmins';

  const getConfigurationValues = (inConfigurationKey) => {
    try {
      const items = orderConfigurations.find(({ key }) => key === inConfigurationKey).value.split(',').map((x) => parseInt(x, 10));
      return items;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let clientIds = cache.get('app:facilityId');
      if (isAdmin) {
        clientIds = null;
      } else if (facilityMode && facilityIds.length !== 0) {
        clientIds = facilityIds;
      }
      const [restaurantIds, orderConfigurations] = await Promise.all([
        getRestaurantIdSchema(null, null, clientIds),
        asyncListAll(getConfigurationByCategoryByStatus, {
          category: '訂單',
          status: { eq: '使用中' },
        })],
      );
      setOrderConfigurations(orderConfigurations);
      setRestaurantOptions(restaurantIds.enum.map((id, index) => ({
        id,
        name: restaurantIds.enumNames[index],
      })));
      setIsLoading(false);
    })();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          id={'restaurantSelect'}
          sx={{ width: 500 }}
          options={restaurantOptions}
          loading={isLoading}
          onChange={async (event, value = {})=> {
            const newRestaurantId = value.id;
            setRestaurantIdSelected(newRestaurantId);
            setMealItemName('');
            setMealItemQuantity(1);
            setMealItemCost(0);
            setMealItemPrice(0);
            if (newRestaurantId && (!restaurantMealOptions[newRestaurantId])) {
              const mealOptions = await asyncListAll(
                getRestaurantMealsByRestaurantByIsActive, { restaurantId: newRestaurantId });
              restaurantMealOptions[newRestaurantId] = mealOptions;
              setRestaurantMealOptions({ ...restaurantMealOptions });
            }
            onUpdate && onUpdate({
              restaurantId: value.id,
              mealItemName: '',
              mealItemQuantity: 1,
              mealItemCost: 0,
              mealItemPrice: 0,
            });
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={t('餐廳')}
              variant="standard"
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <AutoSelect
          id={'mealItem-name'}
          options={restaurantMealOptions[restaurantIdSelected] && restaurantMealOptions[restaurantIdSelected].map(({ name }) => name)}
          values={restaurantMealOptions[restaurantIdSelected] && restaurantMealOptions[restaurantIdSelected].map(({ id }) => id)}
          required={false}
          freeSolo
          value={mealItemName}
          label={`${t('餐點')}名稱`}
          onChange={(value)=>{
            setMealItemName(value);
            const restaruantMeal = restaurantMealOptions[restaurantIdSelected].find(({ id }) => id === value);
            if (restaruantMeal) {
              const { name, cost = 0, price = 0 } = restaruantMeal;
              setMealItemQuantity(1);
              setMealItemCost(cost);
              setMealItemPrice(price);
              onUpdate && onUpdate({
                mealItemName: name,
                mealItemQuantity: 1,
                mealItemCost: cost,
                mealItemPrice: price,
              });
            } else {
              onUpdate && onUpdate({
                mealItemName: value,
              });
            }
          }}
        />
      </Grid>
      <Grid item md={2}>
        <AutoSelect
          id={`mealItem-quantity`}
          fullWidth
          type="number"
          options={getConfigurationValues('餐點數量選單').map((x) => `${x}`)}
          getOptionLabel={(x) => `${x}`}
          values={getConfigurationValues('餐點數量選單')}
          value={mealItemQuantity}
          required={true}
          label="數量"
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          freeSolo={true}
          onChange={(x) => {
            const value = x ? parseFloat(x) : 0;
            setMealItemQuantity(value);
            onUpdate && onUpdate({
              mealItemQuantity: value,
            });
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Grid>
      <Grid item md={3}>
        <AutoSelect
          id={`mealItem-cost`}
          fullWidth
          type="number"
          options={getConfigurationValues('餐點進價選單').map((x) => `${x}`)}
          getOptionLabel={(x) => `${x}`}
          values={getConfigurationValues('餐點進價選單')}
          value={mealItemCost}
          required={true}
          label="進價"
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          freeSolo={true}
          onChange={(x) => {
            const value = x ? parseFloat(x) : 0;
            setMealItemCost(value);
            onUpdate && onUpdate({
              mealItemCost: value,
            });
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Grid>
      <Grid item md={3}>
        <AutoSelect
          id={`mealItem-price`}
          fullWidth
          type="number"
          options={getConfigurationValues('餐點價格選單').map((x) => `${x}`)}
          getOptionLabel={(x) => `${x}`}
          values={getConfigurationValues('餐點價格選單')}
          value={mealItemPrice}
          required={true}
          label="價格"
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          freeSolo={true}
          onChange={(x) => {
            const value = x ? parseFloat(x) : 0;
            setMealItemPrice(value);
            onUpdate && onUpdate({
              mealItemPrice: value,
            });
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Grid>
    </Grid>);
}

RestaurantMealSelect.propTypes = {
  onUpdate: PropTypes.func,
};
