import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { formatAddress, formatTags } from 'utilities/format';
import InfoCard from './InfoCard';
import { request } from 'utilities/graph';
import { getRestaurant } from 'graphql/queries';
import RestaurantEditButton from 'forms/RestaurantForm/RestaurantEditButton';

export default function RestaurantCard({ id, title = `${i18n.t('餐廳')}資料`, data: inData, showMap, ...props }) {
  const [data, setData] = useState({});

  const mappings = [
    { key: 'name', label: '名稱' },
    { key: 'category', label: '類別' },
    { key: 'phoneNumber', label: '電話' },
    { key: 'address', label: '地址', format: formatAddress, showMap },
    { key: 'tags', label: '標籤', format: formatTags },
  ];

  useEffect(() => {
    (async () => {
      if (inData) {
        setData(inData);
      } else
      if (id) {
        const { data: { getRestaurant: data } } = await request(getRestaurant, { id });
        setData(data);
      }
    })();
  }, [inData, id]);

  return (
    <InfoCard
      title={title}
      mappings={mappings}
      data={data}
      link={`/restaurant/${data.id}`}
      editButton={RestaurantEditButton}
      onUpdate={setData}
      {...props}
    />
  );
}

RestaurantCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showMap: PropTypes.bool,
  data: PropTypes.object,
};
