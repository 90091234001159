import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import distance from '@turf/distance';
import { point } from '@turf/helpers';

import {
  counties,
  countiesGeoJson,
} from '@silvergatedelivery/constants';

import { asyncListAll } from 'utilities/graph';
import {
  getRestaurantsByCountyByIsActive,
  getRestaurantMealsByRestaurantByIsActive,
} from './queries';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';
import Loading from 'components/Loading';
import { sortBy } from 'utilities/sorting';
import MealItemDialog from './MealItemDialog';
import cache from 'utilities/cache';
import RestaurantMeals from './RestaurantMeals';
import { compareObjects } from 'utilities/sorting';

let selectedLocations = [];

const addNeighbors = (locations) => {
  const uniqueNeighbors = new Set();

  locations.forEach((location) => {
    const { neighbors } = countiesGeoJson.features.find((x) => x.properties.name === location);
    neighbors.forEach((neighbor) => uniqueNeighbors.add(neighbor));
    uniqueNeighbors.add(location);
  });

  return [...uniqueNeighbors];
};

export default function RestaurantMealList({ onComplete }) {
  // const history = useHistory();

  const [restaurants, setRestaurants] = useState([]);
  const [meals, setMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedMeal, setSelectedMeal] = useState();
  const [selectedRestaurant, setSelectedRestaurant] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [locations, setLocations] = useState(
    cache.get('public:menu-elder') ? [cache.get('public:menu-elder').address.county] : [counties[0]]);
  const [addedMeals, setAddedMeals] = useState([]);

  const handleFilter = (key) => (values) => {
    global.logger.debug({ key, values });

    const newFilters = {
      ...filters,
      [key]: Array.isArray(values) ? values.map(({ label }) => label) : values,
    };
    setFilters(newFilters);
  };

  const handleChangeCounty = async (e, values, reason) => {
    global.logger.debug('handleChangeCounty', values, reason);
    setLocations(values);
    cache.set('public:menu-locations', values);
  };

  const handleAddMeal = (data) => {
    if (!data) {
      return;
    }
    const { meal, quantity } = data;
    const existingAddedMealIndex = addedMeals.findIndex((addedMeal) => addedMeal.meal.id === meal.id);
    if (existingAddedMealIndex !== -1) {
      const newAddedMeals = JSON.parse(JSON.stringify(addedMeals));
      if (quantity === 0) {
        newAddedMeals.splice(existingAddedMealIndex, 1);
      } else {
        newAddedMeals[existingAddedMealIndex].quantity = quantity;
      }
      setAddedMeals(newAddedMeals);
    } else {
      setAddedMeals([...addedMeals, data]);
    }
  };

  const onMealClick = (meal, restaurant) => {
    global.logger.debug('handleAddMeal', meal, restaurant);
    let openMealItemDialog = false;
    if (addedMeals.length !== 0) {
      if (addedMeals[0].meal.restaurantId !== restaurant.id) {
        if (window.confirm('選擇不同餐廳的餐點會重新建立訂單')) {
          setAddedMeals([]);
          openMealItemDialog = true;
          setSelectedQuantity(1);
        } else {
          return;
        }
      } else {
        const existingAddedMeal = addedMeals.find((addedMeal) => addedMeal.meal.id === meal.id);
        openMealItemDialog = true;
        if (existingAddedMeal) {
          setSelectedQuantity(existingAddedMeal.quantity);
        } else {
          setSelectedQuantity(1);
        }
      }
    } else {
      openMealItemDialog = true;
      setSelectedQuantity(1);
    }

    if (openMealItemDialog) {
      setSelectedMeal(meal);
      setSelectedRestaurant(restaurant);
    }
  };

  useEffect(() => {
    if (locations.length === 0) {
      setRestaurants([]);
      setMeals([]);
      return;
    }

    (async () => {
      // 先不要用cache, 因為加入service area後, 用location判斷會出錯
      // const mealCache = cache.get('app:meal-list');
      // if (mealCache &&
      //   mealCache.expiredAt > Date.now() &&
      //   mealCache.locations &&
      //   mealCache.locations.length === locations.length && !mealCache.locations.some((v) => locations.indexOf(v) < 0)
      // ) {
      //   global.logger.debug('use cache for restaurants');
      //   setRestaurants(mealCache.restaurants);
      //   setMeals(mealCache.meals.sort(sortBy('updatedAt', true)));
      //   return;
      // }

      setIsLoading(true);
      let restaurants = [];
      let meals = [];
      const { address: elderAddress } = cache.get('public:menu-elder') || {};
      const locationsAndNeighbors = addNeighbors(locations);

      await Promise.all(locationsAndNeighbors.map(async (county) => {
        const countyRestaurants = await asyncListAll(getRestaurantsByCountyByIsActive, {
          county,
          isActive: {
            eq: 1,
          },
          filter: {
            isPublic: {
              eq: true,
            },
          },
        });
        restaurants = [...restaurants, ...countyRestaurants];
      }));

      const nearbyRestaurants = restaurants.filter((restaurant) => {
        if (restaurant.serviceAreas && restaurant.serviceAreas.length !== 0) {
          if (restaurant.serviceAreas.some((area) => compareObjects(area, {
            county: elderAddress.county,
            district: elderAddress.district,
            zipCode: elderAddress.zipCode,
          }))) {
            return true;
          }
          return false;
        }
        const from = point([restaurant.address.lat, restaurant.address.lng]);
        const to = point([elderAddress.lat, elderAddress.lng]);
        const distanceInKm = distance(from, to);
        return distanceInKm <= 8; // 顯示方圓8KM之內的餐廳
      });

      await Promise.all(nearbyRestaurants.map(async (restaurant) => {
        const restaurantId = restaurant.id;
        const restaurantMeals = await asyncListAll(getRestaurantMealsByRestaurantByIsActive, {
          restaurantId,
          isActive: {
            eq: 1,
          },
          filter: {
            isPublic: {
              eq: true,
            },
          },
        });
        await Promise.all(restaurantMeals.map(async (item) => {
          if (item.imageS3Keys && item.imageS3Keys[0]) {
            item.imageUrl = await Storage.get(item.imageS3Keys[0]);
          }
          // item.restaurant = restaurants.find(({ id }) => id === item.restaurantId);
          item.tagsString = item.tags.items.map((item) => item.tag.label).join(', ');
        }));
        restaurant.meals = restaurantMeals.sort(sortBy('updatedAt', true));
        meals = [...meals, ...restaurantMeals];
      }));
      setRestaurants(nearbyRestaurants);
      setMeals(meals.sort(sortBy('updatedAt', true)));

      // cache.set('app:meal-list', {
      //   expiredAt: Date.now() + 60 * 1000,
      //   locations,
      //   restaurants: nearbyRestaurants,
      //   meals,
      // });

      setIsLoading(false);
    })();
  }, [locations]);

  const onSubmit = () => {
    if (addedMeals.length === 0) {
      window.alert('請選擇餐點');
      return;
    }
    const mealCount = addedMeals.reduce((pre, addedMeal) => pre + addedMeal.quantity, 0);
    if (mealCount > 15) {
      window.alert('最多可選擇15份餐點');
      return;
    }
    const { id: restaurantId, name: restaurantName } = restaurants.find(
      (restaurant) => restaurant.id === addedMeals[0].meal.restaurantId,
    );

    onComplete({
      mealItems: addedMeals.map(({ meal: { name, price }, quantity }) => ({
        name,
        quantity,
        unitValue: price,
        price: price * quantity,
      })),
      restaurantId,
      restaurantName,
    });
  };

  global.logger.debug('restaurants & meals', { restaurants, meals });

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item container spacing={2}>
        {/* <Grid item xs={12}>
          <Typography variant="h5" gutterBottom align="center" style={{ marginTop: 16 }}>
            {'餐點'}
          </Typography>
        </Grid> */}
        <Grid item xs={12} md={2} style={{ display: 'none' }}>
          <Autocomplete
            id="county"
            options={counties}
            defaultValue={locations}
            freeSolo={false}
            multiple
            disableCloseOnSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label={'縣市'} placeholder="" variant="outlined" />
            )}
            onChange={(e, values, reason)=>{
              selectedLocations = values;
              if (reason === 'remove-option') {
                handleChangeCounty(e, selectedLocations);
              }
            }}
            onClose={(e, reason) => {
              global.logger.debug('onClose', reason);
              handleChangeCounty(e, selectedLocations);
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={2} style={{ display: 'none' }}>
          <TextField
            id="mealList_searchByText"
            label={'使用文字搜尋'}
            variant="outlined"
            value={filters.text}
            onChange={(e)=>{
              handleFilter('text')(e.target.value.toLowerCase());
            }}
            fullWidth
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataJoinEditorInput
            title={'營養標籤搜尋'}
            mode={'餐點-營養'}
            joinData={[]}
            defaultValues={filters.tags || []}
            onChange={handleFilter('營養')}
            onUpdateOptions={()=>{}}
            disabled={isLoading}
            showHelperText={false}
            variant="outlined"
            loadOnOpen={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataJoinEditorInput
            title={'食材標籤搜尋'}
            mode={'餐點-食材'}
            joinData={[]}
            defaultValues={filters.tags || []}
            onChange={handleFilter('食材')}
            onUpdateOptions={()=>{}}
            disabled={isLoading}
            showHelperText={false}
            variant="outlined"
            loadOnOpen={true}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        {selectedMeal &&
          <MealItemDialog
            restaurant={selectedRestaurant}
            meal={selectedMeal}
            quantity={selectedQuantity}
            onClose={(data) => {
              handleAddMeal(data);
              setSelectedMeal();
              setSelectedRestaurant();
              setSelectedQuantity(1);
            }}
          />
        }
        {isLoading ? <Loading fullScreen={false} /> : restaurants.map((restaurant) => {
          return <RestaurantMeals
            key={restaurant.id}
            restaurant={restaurant}
            addedMeals={addedMeals[0] && addedMeals[0].meal.restaurantId === restaurant.id ? addedMeals : undefined}
            onMealClick={onMealClick}
            filters={filters}
          />;
        })}
        {!isLoading && restaurants.length !== 0 &&
          <Grid item xs={12} container justifyContent='center'>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              style={{ marginTop: 16 }}
              onClick={onSubmit}
            >
              結帳
            </Button>
          </Grid>
        }
        {!isLoading && restaurants.length === 0 &&
          <Grid
            container
            item xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <p>
              目前尚未開放此區域或是已超出配送範圍，請加入銀色大門詢問客服人員申請。<br/>
              Line：@oqm7360u <br/>
            </p>
            <Button
              variant="outlined"
              color="primary"
              href={'https://lin.ee/8wpxrBL'}
              target="_blank"
            >
              官方賴好友連結
            </Button>
          </Grid>}
      </Grid>
    </Grid>
  );
}

RestaurantMealList.propTypes = {
  onComplete: PropTypes.func,
};
