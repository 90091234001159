import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { request } from 'utilities/graph';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  adminUpdateOrder,
} from 'graphql/mutations';
import { Semaphore } from 'async-mutex';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { toastr } from 'react-redux-toastr';

export default function ResetCancelledOrderButton({
  onUpdate,
  ordersSelected,
}) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const onResetCancelledOrder = async () => {
    const ordersCancelled = ordersSelected.current.filter((order) => order.status === 'cancelled')
      .sort((a, b) => a.date > b.date ? 1 : -1);
    let orderProcessed = 0;
    const toUpdateData = [];

    if (ordersCancelled.some(({ orderGroupId }) => orderGroupId)) {
      toastr.error('無法重置多點配送訂單');
      return;
    }

    if (ordersCancelled.length !== 0) {
      let orderDuration = `修改訂單的送餐日期範圍：${ordersCancelled[0].date} 至 ${ordersCancelled[ordersCancelled.length - 1].date}`;
      if (ordersCancelled[0].date === ordersCancelled[ordersCancelled.length - 1].date) {
        orderDuration = `修改訂單的送餐日期：${ordersCancelled[0].date}`;
      }
      if (window.confirm(`共${ordersCancelled.length}筆已取消的訂單, 確定要修改這${ordersCancelled.length}筆訂單?\n${orderDuration}`)) {
        setOpen(true);
        const s = new Semaphore(10);
        await Promise.all(ordersCancelled.map((order) =>
          s.runExclusive(async () => {
            const data = {
              id: order.id,
              restaurantId: order.restaurantId,
              deliveryBy: order.deliveryBy,
              status: order.deliveryStaffId ? 'ready' : 'waitingForDeliveryStaff',
              tier: order.tier,
              cancellationReason: undefined,
            };
            try {
              await request(adminUpdateOrder, { input: { orders: [data] } });
              toUpdateData.push(data);
            } catch (e) {
              console.log(e);
              if (e.errors && e.errors[0] && e.errors[0].message) {
                toastr.error(e.errors[0].message);
              }
            }
            orderProcessed += 1;
            setProgress(orderProcessed * 100 / ordersCancelled.length);
          }),
        ));
        onUpdate && onUpdate(toUpdateData);
        setOpen(false);
      }
    } else {
      toastr.error('選擇的訂單中沒有已取消的訂單');
    }
  };

  return (
    <>
      <Button
        style={ { marginLeft: 10 } }
        variant="contained"
        color="primary"
        onClick={ async () => {
          await onResetCancelledOrder();
        }}
      >
        重置已取消的訂單
      </Button>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={open}
      >
        <DialogTitle id="form-dialog-title">
          重置訂單中
        </DialogTitle>
        <DialogContent style={{ marginBottom: 30 }}>
          <LinearProgressWithLabel value={progress}/>
        </DialogContent>
      </Dialog>
    </>
  );
}

ResetCancelledOrderButton.propTypes = {
  onUpdate: PropTypes.func,
  ordersSelected: PropTypes.object,
};
