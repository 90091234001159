import {
  countiesDistricts,
} from '@silvergatedelivery/constants';
import { formatAge, formatGender } from 'utilities/format';
import { utils, writeFile, write } from 'xlsx';
const COUNTY = '嘉義縣';
const deliverySubsidy = 125;
const mealCentralGovSubsidyLow = 100;
const mealCentralGovSubsidyMidLow = 90;
const mealLocalGovSubsidyMidLow = 10;


export default function writeChiayi({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  pandagoOrders,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const [year, month] = selectedMonth.split('-');
  const yearMonthTitle = `${parseInt(year)-1911}年${month}月`;

  const workbook = utils.book_new();


  // 移除沒有弱勢身份和其他身份的訂單
  const ordersForDisvantaged = orders.filter((order) => {
    const { disadvantagedTypesSlot } = elders.find((elder) => elder.id === order.elderId) || {};
    if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
      const slot = disadvantagedTypesSlot.find(({ start, end }) => order.date >= start && order.date < end);
      if (slot && (slot.type === '一般' || !['中低2.5倍', '中低1.5倍', '低收'].includes(slot.type))) {
        return false;
      }
    }
    return true;
  });

  utils.book_append_sheet(workbook, getWorksheetElders(elders, ordersForDisvantaged), `個案`);
  utils.book_append_sheet(workbook, getWorksheetStatistics(elders, ordersForDisvantaged, yearMonthTitle), `統計表`);
  utils.book_append_sheet(workbook, getWorksheetStatistics([], [], yearMonthTitle), `核增統計表`);
  utils.book_append_sheet(workbook, getWorksheetAugementList([], []), `核增清冊`);
  utils.book_append_sheet(
    workbook,
    getWorksheetDeliveryFee(elders, ordersForDisvantaged, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName),
    `車馬（送餐大使）`);
  utils.book_append_sheet(
    workbook,
    getWorksheetDeliveryFee(elders, ordersForDisvantaged, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, true),
    `車馬（Pandago）`);

  if (process.env.JEST_WORKER_ID) {
    return write(workbook, { type: 'buffer', bookType: 'xlsx' });
  }

  writeFile(workbook, `${clientName}__${COUNTY}核銷報表__${selectedMonth}.xlsx`, { bookSST: true, cellStyles: true });
}

function getWorksheetElders(elders = [], allOrders = []) {
  const elderRows = [];

  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      identificationCardId,
      gender,
      birthday,
      disadvantagedTypes,
      disadvantagedTypesSlot,
      address: {
        district,
      },
    }) => {
      const elderOrders = allOrders
        .filter(({ elderId }) => elderId === id);
      if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
        disadvantagedTypesSlot.forEach(({ start, end, type }) => {
          if (type === '一般') {
            return;
          }
          const filteredOrders = elderOrders.filter(({ date }) => date >= start && date < end);
          if (filteredOrders.length === 0) {
            return;
          }
          const existingRow = elderRows.find(({ 身分證字號, 身份別 }) => 身分證字號 === identificationCardId && 身份別 === type);
          if (existingRow) {
            existingRow.服務量 += filteredOrders.length;
            existingRow.午餐量 += filteredOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length;
            existingRow.晚餐量 += filteredOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length;
          } else {
            elderRows.push({
              姓名: name,
              身分證字號: identificationCardId,
              性別: formatGender(gender),
              年齡: formatAge(birthday),
              身份別: type,
              服務量: filteredOrders.length,
              午餐量: filteredOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length,
              晚餐量: filteredOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length,
              地址: district,
              核增: '',
            });
          }
        });
      } else {
        if (elderOrders.length === 0) {
          return;
        }
        elderRows.push({
          姓名: name,
          身分證字號: identificationCardId,
          性別: formatGender(gender),
          年齡: formatAge(birthday),
          身份別: disadvantagedTypes,
          服務量: elderOrders.length,
          午餐量: elderOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length,
          晚餐量: elderOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length,
          地址: district,
          核增: '',
        });
      }
    });
  const worksheet = utils.json_to_sheet(elderRows);

  worksheet['!cols'] = [
    { wch: 13 }, { wch: 13 },
    { wch: 5 }, { wch: 6 },
    { wch: 10 }, { wch: 8 },
    { wch: 8 }, { wch: 8 },
    { wch: 8 }, { wch: 10 },
  ];

  return worksheet;
}

function getWorksheetStatistics(elders = [], orders = [], yearMonthTitle) {
  const districs = countiesDistricts.filter(({ county }) => county === COUNTY).reduce((all, { districts: items }) => {
    return [...all, ...items];
  }, []);

  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  let totalLunchCount = 0;
  let totalDinnerCount = 0;
  const districtLunch = districs.reduce((obj, districtName) => {
    obj[districtName] = 0;
    return obj;
  }, { '鄉鎮': '午餐' });

  const districtDinner = districs.reduce((obj, districtName) => {
    obj[districtName] = 0;
    return obj;
  }, { '鄉鎮': '晚餐' });

  orders.forEach(({ elderId, mealSlot }) => {
    const elder = elders.find(({ id }) => id === elderId);
    const { district } = elder.address;
    if (mealSlot === 'lunch') {
      districtLunch[district]++;
      totalLunchCount++;
    } else {
      districtDinner[district]++;
      totalDinnerCount++;
    }
  });

  if (totalLunchCount !== 0 || totalDinnerCount !== 0) {
    districs.forEach((districtName) => {
      if (districtLunch[districtName] === 0 && districtDinner[districtName] === 0) {
        delete districtLunch[districtName];
        delete districtDinner[districtName];
      }
    });
  }

  districtLunch['合計'] = totalLunchCount;
  districtDinner['合計'] = totalDinnerCount;

  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });

  utils.sheet_add_aoa(worksheet, [[`${yearMonthTitle} 老人營養餐飲服務核銷統計表`]], { origin: 'A1' });
  // utils.sheet_add_aoa(worksheet, [['鄉鎮', ...districs, '合計']], { origin: 'A2' });
  // utils.sheet_add_aoa(worksheet, [['午餐']], { origin: 'A3' });
  // utils.sheet_add_aoa(worksheet, [['晚餐']], { origin: 'A4' });
  utils.sheet_add_json(
    worksheet, [districtLunch, districtDinner], { origin: 'A2', skipHeader: false });

  worksheet['!merges'].push({ s: { r: 5, c: 0 }, e: { r: 7, c: 0 } });
  worksheet['!merges'].push({ s: { r: 5, c: 1 }, e: { r: 5, c: 7 } });
  utils.sheet_add_aoa(worksheet, [['服務人數']], { origin: 'B6' });
  worksheet['!merges'].push({ s: { r: 6, c: 1 }, e: { r: 6, c: 2 } });
  utils.sheet_add_aoa(worksheet, [['中低2.5倍']], { origin: 'B7' });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'B8' });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'C8' });
  worksheet['!merges'].push({ s: { r: 6, c: 3 }, e: { r: 6, c: 4 } });
  utils.sheet_add_aoa(worksheet, [['中低1.5倍']], { origin: 'D7' });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'D8' });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'E8' });
  worksheet['!merges'].push({ s: { r: 6, c: 5 }, e: { r: 6, c: 6 } });
  utils.sheet_add_aoa(worksheet, [['低收']], { origin: 'F7' });
  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'F8' });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'G8' });
  worksheet['!merges'].push({ s: { r: 6, c: 7 }, e: { r: 7, c: 7 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'H7' });

  worksheet['!merges'].push({ s: { r: 5, c: 8 }, e: { r: 5, c: 11 } });
  utils.sheet_add_aoa(worksheet, [['服務人次']], { origin: 'I6' });
  worksheet['!merges'].push({ s: { r: 6, c: 8 }, e: { r: 7, c: 8 } });
  utils.sheet_add_aoa(worksheet, [['中低2.5倍']], { origin: 'I7' });
  worksheet['!merges'].push({ s: { r: 6, c: 9 }, e: { r: 7, c: 9 } });
  utils.sheet_add_aoa(worksheet, [['中低1.5倍']], { origin: 'J7' });
  worksheet['!merges'].push({ s: { r: 6, c: 10 }, e: { r: 7, c: 10 } });
  utils.sheet_add_aoa(worksheet, [['低收']], { origin: 'K7' });
  worksheet['!merges'].push({ s: { r: 6, c: 11 }, e: { r: 7, c: 11 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'L7' });

  worksheet['!merges'].push({ s: { r: 5, c: 12 }, e: { r: 5, c: 14 } });
  utils.sheet_add_aoa(worksheet, [['金額']], { origin: 'M6' });
  worksheet['!merges'].push({ s: { r: 6, c: 12 }, e: { r: 7, c: 12 } });
  utils.sheet_add_aoa(worksheet, [['縣市政府補助']], { origin: 'M7' });
  worksheet['!merges'].push({ s: { r: 6, c: 13 }, e: { r: 7, c: 13 } });
  utils.sheet_add_aoa(worksheet, [['中央補助']], { origin: 'N7' });
  worksheet['!merges'].push({ s: { r: 6, c: 14 }, e: { r: 7, c: 14 } });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'O7' });

  const shortYearMonthTitle = yearMonthTitle.replace('年', '').replace('月', '');
  utils.sheet_add_aoa(worksheet, [[`${shortYearMonthTitle}(午餐)`]], { origin: 'A9' });
  utils.sheet_add_aoa(worksheet, [[`${shortYearMonthTitle}(晚餐)`]], { origin: 'A10' });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'A11' });

  const disadvantagedTypeList = ['中低2.5倍', '中低1.5倍', '低收'];
  const getSampleData = () => {
    return {
      // 個案數量
      '中低2.5倍男': 0,
      '中低2.5倍女': 0,
      '中低1.5倍男': 0,
      '中低1.5倍女': 0,
      '低收男': 0,
      '低收女': 0,
      '服務人數合計': 0,
      // 餐點數量
      '中低2.5倍': 0,
      '中低1.5倍': 0,
      '低收': 0,
      '服務人次合計': 0,
      // 金額
      '縣市政府補助': 0,
      '中央補助': 0,
      '金額合計': 0,
    };
  };

  const rate = {
    '縣市': {
      '中低2.5倍': mealLocalGovSubsidyMidLow,
      '中低1.5倍': 0,
      '低收': 0,
    },
    '中央': {
      '中低2.5倍': mealCentralGovSubsidyMidLow,
      '中低1.5倍': mealCentralGovSubsidyLow,
      '低收': mealCentralGovSubsidyLow,
    },
  };

  const data = {
    lunch: getSampleData(),
    dinner: getSampleData(),
    total: getSampleData(),
  };

  elders.forEach(({ id, disadvantagedTypes, disadvantagedTypesSlot, gender }) => {
    if (disadvantagedTypes && !disadvantagedTypeList.includes(disadvantagedTypes)) {
      return;
    }
    const genderString = formatGender(gender);
    const elderOrders = orders.filter(({ elderId }) => elderId === id);

    const countMappings = {
      lunch: false,
      dinner: false,
    };
    elderOrders.forEach(({ mealSlot, date }) => {
      let type = disadvantagedTypes;
      if (disadvantagedTypesSlot && disadvantagedTypesSlot.length !== 0) {
        const slot = disadvantagedTypesSlot.find(({ start, end }) => date >= start && date < end);
        type = slot.type;
      }
      if (!['中低2.5倍', '中低1.5倍', '低收'].includes(type)) {
        return;
      }
      if (type) {
        if (!countMappings[mealSlot]) {
          data[mealSlot][`${type}${genderString}`]++;
          data[mealSlot][`服務人數合計`]++;
          countMappings[mealSlot] = true;
        }

        data[mealSlot][`${type}`]++;
        data[mealSlot][`服務人次合計`]++;

        data[mealSlot]['縣市政府補助'] += 1 * rate['縣市'][type];
        data[mealSlot]['中央補助'] += 1 * rate['中央'][type];
        data[mealSlot]['金額合計'] += 1 * rate['縣市'][type] + 1 * rate['中央'][type];
      }
    });
  });

  Object.keys(data.total).map((key) => {
    data.total[key] = data.lunch[key] + data.dinner[key];
  });

  utils.sheet_add_json(
    worksheet, [data.lunch, data.dinner, data.total], { origin: 'B9', skipHeader: true });

  return worksheet;
}

function getWorksheetAugementList() {
  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
  ];
  utils.sheet_add_aoa(worksheet, [[`照顧組合服務費用項目核增清冊`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [['序號', '身分證號', '個案姓名', '福利身分別', '服務日期', '給(支)付價格', '次數', '申報費用', '目前居住縣市', '目前居住行政區', '服務人員']], { origin: 'A2' });
  return worksheet;
}

function getWorksheetDeliveryFee(elders, orders, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, pandagoSheet = false) {
  const mappings = {};
  let totalCount = 0;

  orders.forEach(({ id: orderId, elderId, mealSlot, deliveryStaffId, date: fullDate }) => {
    const { name: elderName, address: { district } } = elders.find(({ id }) => id === elderId);
    const { name: deliveryStaffName } = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
    const pandagoOrder = pandagoOrders.find((x) => x.orderId === orderId);
    const padnagoDeliveryStaffName = pandagoOrder ? `${pandagoOrder.driver.name} ${pandagoOrder.driver.phoneNumber}` : undefined;

    if (!!pandagoOrder !== pandagoSheet) {
      return;
    }

    const key = `${padnagoDeliveryStaffName || deliveryStaffName}__${deliveryStaffId}__${mealSlot}`;
    mappings[key] = mappings[key] || {
      '鄉鎮': [],
      '編號': '', // fill later
      '志工姓名': padnagoDeliveryStaffName || deliveryStaffName,
      '午餐/晚餐': mealSlot === 'lunch'? '午餐':'晚餐',
      '單價(元)': deliverySubsidy,
      '送餐天數(天)': 0,
      '合計(元)': 0,
      '服務個案姓名': [],
      '送餐日期': {},
    };

    if (!mappings[key]['鄉鎮'].includes(district)) {
      mappings[key]['鄉鎮'].push(district);
    }

    if (!mappings[key]['服務個案姓名'].includes(elderName)) {
      mappings[key]['服務個案姓名'].push(elderName);
    }

    const date = fullDate.split('-').pop();

    if (!mappings[key]['送餐日期'][date]) {
      mappings[key]['送餐天數(天)']++;
      mappings[key]['合計(元)'] += deliverySubsidy;
      mappings[key]['送餐日期'][date] = 0;
      totalCount++;
    }

    mappings[key]['送餐日期'][date] = 1;
  });

  const data = Object.keys(mappings)
    .sort((a, b) => a < b ? 1 : -1)
    .map((key, index) => {
      const item = mappings[key];

      return Object.assign(item, {
        鄉鎮: item['鄉鎮'].join('、\n'),
        編號: index + 1,
        服務個案姓名: item['服務個案姓名'].reduce((groups, item) => {
          const lastGroup = groups[groups.length - 1];
          if (lastGroup && lastGroup.length < 3) {
            lastGroup.push(item);
          } else {
            groups.push([item]);
          }
          return groups;
        }, []).map((group) => `${group.join('、')}`).join('\n'),
        送餐日期: Object.keys(item['送餐日期'])
          .sort((a, b) => a > b ? 1 : -1)
          .reduce((array, dateKey) => {
            const previousItem = array[array.length - 1];

            if (previousItem && previousItem.dateKey) {
              const [from, to] = previousItem.dateKey.split('-');
              const lastDate = to || from;
              if (parseInt(lastDate) + 1 === parseInt(dateKey)) {
                previousItem.dateKey = `${from}-${dateKey}`;
                previousItem.value += item['送餐日期'][dateKey];
                return array;
              }
            }

            array.push({
              dateKey,
              value: item['送餐日期'][dateKey],
            });

            return array;
          }, [])
          .map(({ dateKey, value }) => `${dateKey}(${value})`)
          .join(', '),
      });
    });

  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
  ];
  utils.sheet_add_aoa(worksheet, [[`補助長期照顧營養餐飲服務 ${yearMonthTitle} 志工服務及志工交通費${pandagoSheet ? '(熊貓)' : '(大使)'}`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [[`服務單位: ${clientName}`]], { origin: 'A2' });
  utils.sheet_add_aoa(worksheet, [['鄉鎮', '編號', '志工姓名', '午餐/晚餐', '單價(元)', '送餐天數(天)', '合計(元)', '服務個案姓名', '送餐日期']], { origin: 'A3' });
  utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: true });

  const offset = 3 + data.length;
  worksheet['!merges'].push({ s: { r: offset, c: 0 }, e: { r: offset, c: 4 } });
  utils.sheet_add_aoa(worksheet, [[`合計`]], { origin: `A${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount]], { origin: `F${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount * deliverySubsidy]], { origin: `G${offset+1}` });

  utils.sheet_add_aoa(worksheet, [[`承辦人簽章：`]], { origin: `A${offset+3}` });
  utils.sheet_add_aoa(worksheet, [[`主管簽章：`]], { origin: `F${offset+3}` });

  worksheet['!cols'] = [
    { wch: 13 },
    { wch: 5 },
    { wch: 20 },
    { wch: 13 },
    { wch: 8 },
    { wch: 13 },
    { wch: 10 },
    { wch: 45 },
    { wch: 40 },
  ];

  return worksheet;
}
