import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Storage } from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// import ListIcon from '@material-ui/icons/List';
// import AppsIcon from '@material-ui/icons/Apps';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Semaphore } from 'async-mutex';

import {
  getDeliveryStaffOrdersByStaffByStatus,
  getDeliveryStaffOrdersByStatusByDate,
  getDeliveryStaffOrdersByCountyByDeliveryBy,
  listDeliveryStaffOrders,
  getDeliveryStaffOrdersByElderByDate,
  getDeliveryStaffOrdersByClientByDate,
} from './queries';
import QueryGenerator from 'components/QueryGenerator';
import DeliveryStaffOrderDiaryCard from './DeliveryStaffOrderDiaryCard';
import { TIME_ZONE, counties } from '@silvergatedelivery/constants';
import { request } from 'utilities/graph';
import cache from 'utilities/cache';

const pastThreeDays = moment().tz(TIME_ZONE).add(-3, 'days').startOf('day').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).endOf('day').format('YYYY-MM-DD');

let componentRef;

const DEFAULT_IMAGE_URL = '/images/default_delivery_diary.png';
const INFINITE_DATA_SHOW_PER_REQUEST = 12;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // overflow: 'visible',
    // height: 'calc(100vh - 64px)',
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export default function DeliveryDiary({
  deliveryStaffId,
  elderId,
  clientId,
  showFavorite: inShowFavorite = false,
  showIssue: inShowIssue = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [showedData, setShowedData] = useState([]);
  const [hideNames, setHideNames] = useState(false);
  const [showTransferOnly, setShowTransferOnly] = useState(false);
  const [showNotAtHome, setShowNotAtHome] = useState(false);
  const [showFavorite, setShowFavorite] = useState(inShowFavorite);
  const [showIssue, setShowIssue] = useState(inShowIssue);
  const [filteredData, setFilteredData] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  // const [viewMode, setViewMode] = useState('grid');

  let indexes = [
    {
      name: 'getDeliveryStaffOrdersByCountyByDeliveryBy',
      label: '按地區查詢',
      partitionKey: 'county',
      sortKey: 'deliveryBy',
      fields: [
        {
          label: '縣市',
          key: 'county',
          type: 'select',
          options: counties.map((item) => {
            return { value: item, label: item };
          }),
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
      ],
      operation: getDeliveryStaffOrdersByCountyByDeliveryBy,
    },
    {
      name: 'getDeliveryStaffOrdersByStatusByDate',
      label: `按${t('送餐')}時間查詢`,
      partitionKey: 'status',
      sortKey: 'deliveryBy',
      fields: [
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
      ],
      operation: getDeliveryStaffOrdersByStatusByDate,
    },
    {
      name: 'scan',
      label: '掃描全部資料',
      fields: [{
        label: '狀態',
        key: 'status',
        type: 'select',
        disabled: true,
        options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
      }],
      operation: listDeliveryStaffOrders,
    },
  ];

  useEffect(() => {
    let newFilteredData = data;
    if (showTransferOnly) {
      newFilteredData = newFilteredData.filter((data) => data.checkListTransfer ||
          (data.order && (data.order.category === '送物資' || data.order.category === '送餐和物資')));
    }
    if (showNotAtHome) {
      newFilteredData = newFilteredData.filter(({ deliveryResult }) => deliveryResult === 'leftThere' || deliveryResult === 'receivedByRelative');
    }
    if (showFavorite) {
      newFilteredData = newFilteredData.filter(({ favorite }) => !!favorite);
    }
    if (showIssue) {
      newFilteredData = newFilteredData.filter(({ hasIssue }) => hasIssue);
    }
    setFilteredData(newFilteredData);
    setShowedData([...newFilteredData.filter((x, index) => index < INFINITE_DATA_SHOW_PER_REQUEST)]);
  }, [data, showTransferOnly, showNotAtHome, showFavorite, showIssue]);

  if (clientId) {
    indexes = [{
      name: 'getDeliveryStaffOrdersByClientByDate',
      label: '按客戶查詢',
      partitionKey: 'clientId',
      sortKey: 'deliveryBy',
      fields: [
        {
          label: '客戶ID',
          key: 'clientId',
          type: 'select',
          disabled: true,
          options: [{ value: clientId, label: clientId }],
        },
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
      ],
      operation: getDeliveryStaffOrdersByClientByDate,
    }];
  }

  if (elderId) {
    indexes = [{
      name: 'getDeliveryStaffOrdersByElderByDate',
      label: `按${t('送餐對象')}查詢`,
      partitionKey: 'elderId',
      sortKey: 'deliveryBy',
      fields: [
        {
          label: `${t('送餐對象')}ID`,
          key: 'elderId',
          type: 'select',
          disabled: true,
          options: [{ value: elderId, label: elderId }],
        },
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
      ],
      operation: getDeliveryStaffOrdersByElderByDate,
    }];
    if (inShowFavorite) {
      indexes[0].fields.push({
        label: '收藏',
        key: 'favorite',
        type: 'select',
        disabled: true,
        options: [{ value: true, label: '已收藏' }],
      });
    }
    if (inShowIssue) {
      indexes[0].fields.push({
        label: '追蹤',
        key: 'hasIssue',
        type: 'select',
        disabled: true,
        options: [{ value: true, label: '已追蹤' }],
      });
    }
  }

  if (deliveryStaffId) {
    indexes = [{
      name: 'getDeliveryStaffOrdersByStaffByStatus',
      label: `按${t('送餐大使')}查詢`,
      partitionKey: 'deliveryStaffId',
      sortKey: 'status',
      fields: [
        {
          label: `${t('送餐大使')}ID`,
          key: 'deliveryStaffId',
          type: 'select',
          disabled: true,
          options: [{ value: deliveryStaffId, label: deliveryStaffId }],
        },
        {
          label: '狀態',
          key: 'status',
          type: 'select',
          disabled: true,
          options: [{ value: 'completed', label: t('完成 (送餐日誌)') }],
        },
        {
          label: `${t('送餐')}時間`,
          key: 'deliveryBy',
          type: 'datetime',
        },
      ],
      operation: getDeliveryStaffOrdersByStaffByStatus,
    }];
  }

  const queryDefaultParams = {
    status: 'completed',
    deliveryStaffId,
    clientId,
    elderId,
    deliveryBy: {
      from: pastThreeDays,
      to: today,
    },
    county: cache.get('app:location') || counties[0],
  };
  if (inShowFavorite) {
    queryDefaultParams.favorite = true;
  }
  if (inShowIssue) {
    queryDefaultParams.hasIssue = true;
  }

  const handleUpdate = async (items) => {
    setItemCount(items.length);
    setOrderCount(0);
    const s = new Semaphore(10);
    const updatedItems = await Promise.all(items.map((deliveryStaffOrder) =>
      s.runExclusive(async () => {
        if (!deliveryStaffOrder.order) {
          try {
            const { data: { getOrder: order } } = await request( /* GraphQL */ `
                  query GetOrder($id: ID!) {
                    getOrder(id: $id) {
                      county
                      category
                      elder {
                        name
                        nickname
                      }
                    }
                  }
                `, { id: deliveryStaffOrder.orderId });
            deliveryStaffOrder.order = order;
          } catch (e) {
            console.log(e);
          }
        }

        deliveryStaffOrder.photoS3Keys = deliveryStaffOrder.photoS3Keys || [];

        if (deliveryStaffOrder.photoS3Keys[0] && deliveryStaffOrder.thumbnail !== DEFAULT_IMAGE_URL) {
          deliveryStaffOrder.thumbnail = await Storage.get(deliveryStaffOrder.photoS3Keys[0]);
        } else {
          deliveryStaffOrder.thumbnail = DEFAULT_IMAGE_URL;
        }
        setOrderCount((prevCounter) => prevCounter + 1);
        return deliveryStaffOrder;
      }),
    ));
    // global.logger.debug('updatedItems', updatedItems);

    const allData = updatedItems.filter((x) => x);
    setData([...allData]);
  };

  global.logger.debug('data', {
    data: data.length,
    showedData: showedData.length,
    hasMore: showedData.length < data.length,
  });

  return (
    <InfiniteScroll
      scrollableTarget="app-container"
      dataLength={showedData.length}
      next={(...args) => {
        global.logger.debug('next', args);
        if (showedData.length === filteredData.length) return;
        setShowedData([...filteredData.filter((x, index) => index < showedData.length + INFINITE_DATA_SHOW_PER_REQUEST)]);
      }}
      height='calc(100vh - 116px)' // 64px appbar + 52px footer
      hasMore={showedData.length < filteredData.length}
      loader={
        <p style={{ textAlign: 'center' }}>
          <h4>載入中...</h4>
        </p>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
        </p>
      }
    >
      <Grid container spacing={1} className={classes.root} id="delivery-diary-container">
        <Grid item xs={12} >
          <QueryGenerator
            indexes={indexes}
            defaultIndex={0}
            defaultParams={queryDefaultParams}
            onUpdate={handleUpdate}
            appendNewData={true}
            limit={200}
          />
        </Grid>
        {(data.length > 0) &&
        <Grid item xs={12} align="center">
          {/* <FormControl>
            <ButtonGroup>
              <IconButton color={viewMode === 'grid' ? 'primary': 'default'} component="label" onClick={() => setViewMode('grid')}>
                <AppsIcon />
              </IconButton>
              <IconButton color={viewMode === 'table' ? 'primary': 'default'} component="label" onClick={() => setViewMode('table')}>
                <ListIcon />
              </IconButton>
            </ButtonGroup>
        </FormControl> */}
          {orderCount !== itemCount &&
            <Typography variant='body1'>
              {`下載資料(${orderCount}/${itemCount})`}
            </Typography>}
          <FormControlLabel
            control={
              <Checkbox
                checked={hideNames}
                value={hideNames}
                onChange={(e) => {
                  setHideNames(e.target.checked);
                }}
                name={'hideNames'}
                color="primary"
              />
            }
            label="隱藏姓名"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showTransferOnly}
                value={showTransferOnly}
                onChange={(e) => {
                  setShowTransferOnly(e.target.checked);
                }}
                name={'showTransferOnly'}
                color="primary"
              />
            }
            label="只顯示轉贈愛心物資"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showNotAtHome}
                value={showNotAtHome}
                onChange={(e) => {
                  setShowNotAtHome(e.target.checked);
                }}
                name={'showNotAtHome'}
                color="primary"
              />
            }
            label="只顯示個案未遇"
          />
          {!inShowFavorite && !inShowIssue &&
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showFavorite}
                  value={showFavorite}
                  onChange={(e) => {
                    setShowFavorite(e.target.checked);
                  }}
                  name={'showFavorite'}
                  color="primary"
                />
              }
              label="只顯示收藏"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showIssue}
                  value={showIssue}
                  onChange={(e) => {
                    setShowIssue(e.target.checked);
                  }}
                  name={'showIssue'}
                  color="primary"
                />
              }
              label="只顯示追蹤"
            />
          </>}
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <Button color="primary" variant="contained">列印頁面</Button>;
            }}
            content={() => {
              const printContents = componentRef.cloneNode(true);
              const noPrintElements = printContents.getElementsByClassName('no-print');
              Array.from(noPrintElements).forEach((element) => element.remove());
              return printContents;
            }}
          />
        </Grid>}
        <Grid container spacing={2} style={{ padding: 8 }} ref={(el) => componentRef = el }>
          {showedData.map((item, index)=>(
            <Grid item xs={4} sm={4} md={4} lg={3} key={index}>
              <DeliveryStaffOrderDiaryCard
                deliveryStaffOrder={item}
                hideNames={hideNames}
                onUpdate={(newDeliveryStaffOrder) => {
                  const newData = data.map((item) => item.id === newDeliveryStaffOrder.id ? newDeliveryStaffOrder : item);
                  setData(newData);
                }}
              />
              {(index + 1) % 6 === 0 && index + 1 !== showedData.length && <div className="page-break" />}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </InfiniteScroll>
  );
}

DeliveryDiary.propTypes = {
  deliveryStaffId: PropTypes.string,
  elderId: PropTypes.string,
  clientId: PropTypes.string,
  showFavorite: PropTypes.bool,
  showIssue: PropTypes.bool,
};
