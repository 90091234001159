import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function AutoSelect({
  id,
  options = [],
  values = [],
  value: inValue,
  onChange,
  label,
  type,
  helperText = '',
  required = false,
  freeSolo = true,
  disabled = false,
  getOptionLabel,
}) {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (typeof inValue !== 'undefined') {
      const matchedIndex = values.findIndex((v) => v === inValue);
      if (matchedIndex !== -1) {
        setValue(inValue);
        setInputValue(options[matchedIndex]);
        return;
      } else if (freeSolo) {
        setValue('');
        if (typeof inValue === 'number') {
          setInputValue(inValue.toString());
        } else {
          setInputValue(inValue);
        }
      }
    }
  }, [inValue, values, options]);

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        type={type}
        label={label}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        helperText={helperText}
        inputProps={Object.assign(params.inputProps, {
          value: inputValue,
        })}
        disabled={disabled}
      />);
  };

  return (
    <Autocomplete
      id={id}
      options={options}
      freeSolo={freeSolo}
      value={value || ''}
      getOptionLabel={getOptionLabel}
      required={required}
      onChange={(event, newItem) => {
        if (newItem) {
          const matchedIndex = options.findIndex((label) => label === newItem);
          onChange(values[matchedIndex]);
        } else if (freeSolo) {
          onChange(newItem);
        }
      }}
      inputValue={inputValue || ''}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset') return;

        setInputValue(newInputValue);

        if (newInputValue) {
          const matchedIndex = type === 'number' ?
            values.findIndex((value) => value === newInputValue):
            options.findIndex((label) => label === newInputValue);
          if (matchedIndex !== -1) {
            onChange(values[matchedIndex]);
          } else if (freeSolo) {
            onChange(newInputValue);
          }
        }
      }}
      renderInput={renderInput}
      disabled={disabled}
    />);
}


AutoSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  freeSolo: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
};
