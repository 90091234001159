export const getTagsUiSchema = () => {
  return {
    'items': {
      'category': {
        'ui:width': '40%',
      },
      'name': {
        'ui:width': '60%',
      },
    },
  };
};

export const getMealItemsUiSchema = () => {
  return {
    'items': {
      'name': {
        'ui:width': '30%',
      },
      'quantity': {
        'ui:width': '12%',
      },
      'cost': {
        'ui:width': '12%',
      },
      'price': {
        'ui:width': '12%',
      },
      'note': {
        'ui:width': '34%',
        'ui:emptyValue': '',
      },
      'ui:title': '',
    },
    'ui:options': {
      'orderable': false,
    },
  };
};

export const getEmergencyContactUiSchema = () => {
  return {
    'name': {
      'ui:width': '50%',
    },
    'relationship': {
      'ui:width': '50%',
    },
    'phoneNumber': {
      'ui:width': '50%',
    },
    'phoneNumber2': {
      'ui:width': '50%',
      'ui:emptyValue': '',
    },
  };
};

export const getAddressUiSchema = () => {
  return {
    'zipCode': {
      'ui:width': '100%',
    },
    'county': {
      'ui:width': '50%',
    },
    'district': {
      'ui:width': '50%',
    },
    'street': {
      'ui:width': '100%',
    },
    'note': {
      'ui:width': '100%',
    },
    'lat': {
      'ui:width': '50%',
    },
    'lng': {
      'ui:width': '50%',
    },
    'place': {
      'ui:widget': 'place',
      'ui:width': '100%',
    },
    'ui:order': [
      'county', 'district', 'street', 'note', 'lat', 'lng',
    ],
  };
};
