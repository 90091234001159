import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import { request } from 'utilities/graph';
import {
  updateDeliveryStaffOrder,
} from 'graphql/mutations';
import cache from 'utilities/cache';

export default function DeliveryStaffOrderDiaryCardDialog({
  data: inData,
  buttonStyle = {},
  buttonSize = 'medium',
  onUpdate,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(inData);

  const handleSubmit = async () => {
    setIsLoading(true);
    const username = cache.get('app:username');
    const {
      id,
      diary,
    } = data;
    const input = {
      id,
      diary,
      updatedBy: username,
    };
    const { data: { updateDeliveryStaffOrder: result } } = await request(updateDeliveryStaffOrder, { input });
    Object.assign(result, {
      order: inData.order,
      photoS3Keys: inData.photoS3Keys,
      thumbnail: inData.thumbnail,
    } );
    onUpdate(result);

    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Tooltip title={'編輯'} style={{}}>
        <IconButton
          data-testid={'edit'}
          aria-label={'edit'}
          onClick={() => setOpen(true)}
          size={buttonSize}
        >
          <EditIcon fontSize={'inherit'} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={(e, reason)=>{
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
          handleClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('送餐關懷')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <TextField
                margin="dense"
                id="diary"
                label="日誌"
                type="text"
                value={data.diary}
                fullWidth
                required
                multiline={true}
                minRows={5}
                onChange={(e) => setData({ ...data, diary: e.target.value }) }
                style={{ width: 360 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isLoading}>
            取消
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isLoading || !data.diary}>
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

DeliveryStaffOrderDiaryCardDialog.propTypes = {
  data: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonSize: PropTypes.string,
  onUpdate: PropTypes.func,
};
